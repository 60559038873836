export class MedioPagoData {
    idRelacionalProcesoPago: number;
    canal: string;
    pais: string;
    franquicia: string;  
    medioDePagoPresencial: string;
    medioDePago: string;
    estado: boolean; 
}


export class ResponseServiceUpdateMedioPago {
    result: number;
}