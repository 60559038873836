export class Permisos {
  path: string;
  groups: string[];
}

export class RequestGestionModulos {
  request: Permisos[];
}


