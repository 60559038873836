import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { formatDate, UpperCasePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { RequestStatus } from '../../shared/request-status';
import { TransactionStatus } from '../../shared/response-status';
import { RelanzamientosService } from '../../services/relanzamientos.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-vision',
  templateUrl: './vision.component.html',
  styleUrls: ['./vision.component.css']
})
export class VisionComponent implements OnInit {
  @ViewChild('mdbTablePaginationResult', { static: true }) mdbTablePaginationResult: MdbTablePaginationComponent;
  @ViewChild('tableEl', { static: true }) tableEl: MdbTableDirective;

  public dateInitial: NgbDateStruct | null;
  public date: Date | null;
  public startAt = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 0, 0);
  public min = new Date();
  public max = new Date();
  public previous: any = [];
  public order: string | null;
  public userVision: string | null;
  public pwdVision: string | null;
  private requestStatus: RequestStatus;
  public logginUser: boolean = false;
  public transactionsList: TransactionStatus[] = [];

  constructor(
    private spinnerService: NgxSpinnerService,
    private cdRef: ChangeDetectorRef,
    private relanzamientosService: RelanzamientosService) {
    this.requestStatus = new RequestStatus();
    this.date = null;
    this.order = null;
    this.userVision = null;
    this.pwdVision = null;
  }

  ngOnInit(): void {
  }

  public loginVision(): any {
    (this.userVision === environment.userExterno) && (this.pwdVision === environment.passwordExterno) ? this.logginUser = true : Swal.fire('Error', 'El usuario o contraseña son incorrectos ', 'error');
  }
 
  public loginDisabled(): boolean {
    if ((this.userVision === null || this.userVision === '') || (this.pwdVision === null || this.pwdVision === '')) {
      return true;
    } else {
      return false;
    }
  }

  public searchStatus(): void {
    this.spinnerService.show();
    
    this.requestStatus['order-id'] = this.order.toUpperCase();
       
    if (this.date === null) {
      this.requestStatus.date = '';
    } else {
      let fechInicio = this.date.toLocaleDateString('en-US');
      this.requestStatus.date = formatDate(fechInicio, 'yyyyMMdd', 'en-US');
    }

    this.relanzamientosService.visionReport(this.requestStatus)
      .subscribe(statusReponse => {
        this.transactionsList = [];
        if (statusReponse.transactions !== undefined) {
          if (statusReponse.transactions.length > 0) {
            statusReponse.transactions.forEach(element => {
              this.transactionPush(element);
            });
          } else if (statusReponse.transactions.clientId) {
            this.transactionPush(statusReponse.transactions);
          } else {
            Swal.fire('La búsqueda no arrojo resultados', '', 'info')
          }
        } else {
          Swal.fire('La búsqueda no arrojo resultados', '', 'info');
        }
        this.spinnerService.hide();
      }, err => {
        this.spinnerService.hide();
      });
  }
 
  public buttonDisabled(): boolean {
    if (this.order === null || this.order === '') {
      return true;
    } else {
      return false;
    }
  }

  private transactionPush(element){
     let transaction: TransactionStatus = element;
    transaction.pricingAmount = this.convertDecimal(element.pricingAmount,element.paymentDecimal);
    transaction.paymentAmount = this.convertDecimal(element.paymentAmount,element.paymentDecimal);
    transaction.sessionAmount = this.convertDecimal(element.sessionAmount,element.paymentDecimal);
    transaction.capturedAmount = this.convertDecimal(element.capturedAmount,element.paymentDecimal);
    transaction.authAmount = this.convertDecimal(element.authAmount,element.paymentDecimal);
    this.transactionsList.push(transaction);
  }

  private convertDecimal(entero:number,decimalPosition:number): string {
    const numberText = entero.toString();
    const pointPosition = numberText.length - decimalPosition;

    const numberDecimal = numberText.slice(0,pointPosition) + '.' + numberText.slice(pointPosition);

    return numberDecimal;
  }
}
