import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { FpGenericas, Response} from '../../shared/fp-genericas';
import { RelanzamientosService } from '../../services/relanzamientos.service';
import { ResponseServiceFPEmision, Success, Failed, PnrStateful } from '../../shared/responseserviceFPemision';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import * as Papa from 'papaparse';
import { RequestServiceFPEmision, Pnr } from 'src/app/shared/requestserviceFPemision';
import { isNull } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-fp-genericas',
  templateUrl: './fp-genericas.component.html',
  styleUrls: ['./fp-genericas.component.css']
})
export class FpGenericasComponent implements OnInit, AfterViewInit {
  @ViewChild('mdbTablePagination', { static: true })  mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('mdbTablePaginationResult', { static: true })  mdbTablePaginationResult: MdbTablePaginationComponent;
  @ViewChild('tableEl', { static: true }) tableEl: MdbTableDirective;
  @ViewChild('tableE2', { static: true }) tableE2: MdbTableDirective;
  @Output() newItemEvent = new EventEmitter<RequestServiceFPEmision>();

  listTGenericas : FpGenericas[]=[];
  requestGenericFPEmision : RequestServiceFPEmision;
  resultEmision : ResponseServiceFPEmision;
  listPnrSucces: Success;
  listPnrFallidos: Failed;
  listpnrEmitidos: PnrStateful[] = [];
  @ViewChild('form') form;
  previous: any = [];
  listValidacionesFallidas = [];

  constructor(private relanzamientosService: RelanzamientosService, private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.requestGenericFPEmision = new RequestServiceFPEmision();
    this.requestGenericFPEmision.request = [];
   }

  ngOnInit(): void {
  }

  onChange(files: File[]){
    this.listTGenericas = [];
    this.listpnrEmitidos = [];
    var ext =this.validaFile(files);
    if (ext){
      if(files[0]){
        Papa.parse(files[0], {
          header: true,
          skipEmptyLines: true,
          complete: (result,file) => {
            console.log(result);
            var cabeceras = this.validaCabeceras(result.meta.fields);
            if(cabeceras){
              //Validaciones medio pago
              this.listValidacionesFallidas = [];
              let mensajeValidacion: string ="<br/> *Fallido PNR: ";
              for(const item of result.data){
                if(item.mediopago.toUpperCase() != "TC" && item.mediopago.toUpperCase() != "PSE"){
                  this.listValidacionesFallidas.push(mensajeValidacion.concat(item.pnr + ' columna medio de pago debe ser TC o PSE'));
                }
                else if(item.mediopago.toUpperCase() == 'TC' && item.cus){
                  this.listValidacionesFallidas.push(mensajeValidacion.concat(item.pnr + ' columna cus debe estar vacía para medio de pago TC'));
                }
                else if(item.mediopago.toUpperCase() == 'PSE' && item.franquicia){
                  this.listValidacionesFallidas.push(mensajeValidacion.concat(item.pnr + ' columna franquicia debe estar vacía para medio de pago PSE'));
                }
              }
              if(this.listValidacionesFallidas.length === 0){
                this.listTGenericas = result.data;
                this.configureTable();
              }else{
                Swal.fire(this.listValidacionesFallidas.toString(), '', 'info');
                this.spinnerService.hide();
                this.reset();
              }
            }
            else{
              Swal.fire('El archivo no cuenta con las cabeceras correspondientes, favor de validar', '', 'info');
              this.spinnerService.hide();
              this.reset();
            }
          }
        });
      }
    }
    else{
      Swal.fire('El archivo no es de tipo csv, intente con otro archivo', '', 'info');
      this.spinnerService.hide();
      this.reset();
    }
  }
  
  configureTable(): void {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);
    this.tableEl.setDataSource(this.listTGenericas);
    this.listTGenericas = this.tableEl.getDataSource();
    this.previous = this.tableEl.getDataSource();
    
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.mdbTablePagination.firstPage();
    this.cdRef.detectChanges();
  }

  reset() {
    this.form.nativeElement.reset()
  }

  validaFile(files: File[]){
    if (files[0].name.includes('.csv') || files[0].name.includes('.CSV')){
      return true;
    }
    else{
      return false;
    }
  }

  validaCabeceras(file:any[]){
    if (file.includes('pnr') && file.includes('apellidos') && file.includes('franquicia') && file.includes('officeID') && file.includes('mediopago') && file.includes('cus') ){
      return true;
    }
    else{
      return false;
    }
  }

  sendEmisiones(): void {
    let mensaje: string ="Fallido: ";
    this.spinnerService.show();
    this.previous = [];
    this.requestGenericFPEmision.request = this.getListPnrs();
    //this.listTGenericas = [];
    this.relanzamientosService.sendEmisionesFPGenericas(this.requestGenericFPEmision).subscribe(response => {
      this.resultEmision = response;
      this.resultEmision.Failed.forEach(element => {
        let statusPnr: PnrStateful = new PnrStateful();
        
        if(this.resultEmision.validationError == true){
          for(const item of this.requestGenericFPEmision.request){
            if(element.pnr == item.pnr ){
              statusPnr.pnr = item.pnr;
              statusPnr.lastName = item.lastName;
              statusPnr.franquicia = item.franquicia; 
              statusPnr.oid = item.oid;
              statusPnr.paymentMethod = item.paymentMethod;
              statusPnr.cus = item.cus;
            }
          }
          statusPnr.status = mensaje.concat(' Campo: ' + element.field + ', Valor: ' + element.value + ', Mensaje: ' + element.message);
          console.log(statusPnr);
        }
        else{
          statusPnr.pnr = element.pnr;
          statusPnr.lastName = element.lastName;
          statusPnr.franquicia = element.franquicia; 
          statusPnr.oid = element.oid;
          statusPnr.paymentMethod = element.paymentMethod;
          statusPnr.cus = element.cus;
          statusPnr.status = mensaje.concat(element.error);
        }
        this.listpnrEmitidos.push(statusPnr);
      });
      this.resultEmision.Success.forEach(element => {
        // tslint:disable-next-line: prefer-const
        let statusPnr: PnrStateful = new PnrStateful();
        statusPnr.pnr = element.pnr;
        statusPnr.lastName = element.lastName;
        statusPnr.franquicia = element.franquicia; 
        statusPnr.oid = element.oid;
        statusPnr.paymentMethod = element.paymentMethod;
        statusPnr.cus = element.cus;
        statusPnr.status = 'Forma de pago agregada exitosamente';
        this.listpnrEmitidos.push(statusPnr);

      });
      
      this.configureTableResult();  
      this.spinnerService.hide();
    }, err => {
      console.log(err);
      this.spinnerService.hide();
    });
  }

  getListPnrs(): Pnr[] {
    let listPnrSelected: Pnr[] = [];
    for (const item of this.listTGenericas) {
      const pnrSelected = new Pnr();
      pnrSelected.pnr = item.pnr;
      pnrSelected.lastName = item.apellidos;
      pnrSelected.oid = item.officeID;
      pnrSelected.franquicia = item.franquicia;
      pnrSelected.paymentMethod = item.mediopago;
      pnrSelected.cus = item.cus;
      console.log(pnrSelected);
      listPnrSelected.push(pnrSelected);
    }
    return listPnrSelected;
  }

  configureTableResult(): void {
    this.mdbTablePaginationResult.setMaxVisibleItemsNumberTo(10);
    this.tableE2.setDataSource(this.listpnrEmitidos);
    this.listpnrEmitidos = this.tableE2.getDataSource();
    this.previous = this.tableE2.getDataSource();

    this.mdbTablePaginationResult.calculateFirstItemIndex();
    this.mdbTablePaginationResult.calculateLastItemIndex();
    this.mdbTablePaginationResult.firstPage();
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {

  }

}
