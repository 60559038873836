export class Amount {
    moneda: string;
    exponente: number;
    valor: number;
}

export class ReembolsosWP {
    pais: string;
    canal: string;
    descripcion: string;
    pnr: string;
    monto: Amount;
}

export class RequestReembolsosWP {
    request: ReembolsosWP[];
}
