export class Duplex {

    registro: number;
    nombre: string;
    aerolinea: string;
    vuelo: string;
    clase: string;
    fecha: string;
    ruta: string;
    cantidad: string;
    reserva: string;
    selected: boolean | false;
    eliminar: boolean | false;
    notas: string;
}
