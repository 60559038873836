import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthGuardGuard } from '../guards/auth-guard.guard';
import { AuthService } from '../../auth.service';
import { GestionModulosService } from '../../services/gestionmodulos.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styles: [
  ]
})
export class NavbarComponent implements OnInit {

  constructor(public authService: AuthService,
    public authGuard: AuthGuardGuard,
    public gestionModulosService: GestionModulosService) { }

  public pathExterno: boolean = false;

  ngOnInit(): void {
    if (document.location.pathname.includes("modulo-externo")) {
      this.pathExterno = true;
    } else {
      this.pathExterno = false;
      this.gestionModulosService.getGestionModulos().subscribe(res => {
        environment.permisosApp = res.request
      });
    }
  }

  public logoff(): void {
    this.authService.logout();
    this.authService.updateLoggedInStatus();
  }

}
