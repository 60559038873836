import { PnrVoid } from './request-service-void-worldpay';
export class ResponseServiceVoidWorldpay {
    Success: Success[];
    Failed: Failed[];
    validationError: boolean;
}

export class Success {
    canal: string;
    moneda: string;
    pais: string;
    pnr: string;
}

export class Failed {
    canal: string;
    moneda: string;
    pais: string;
    pnr: string;
    error: string;
    field: string;
    value: string;
    message: string;
}

export class PnrStateful {
    canal: string;
    moneda: string;
    pais: string;
    pnr: string;
    status: string;
}
