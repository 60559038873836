<app-navbar></app-navbar>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="container">

    <form class="form-inline my-5">
        <div class="form-group">
            <div class="container ">

                <div class="input-group text-app-color">
                        <label for="pnr" class="col-form-label">PNR</label>
                    <div class="input-group-append col-xs-auto col-sm-auto col-md-2">
                        <input type="text" class="form-control col-xs-auto col-sm-auto col-md-auto" name="pnr" (click)="claerdp()" [(ngModel)]="filterPnr" #pnrfilter="ngModel">
                    </div>
                        <label for="dp" class="col-form-label">Fecha Inicio</label>
                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                        <input [owlDateTime]="dt1" placeholder="MM/dd/yyyy, HH:mm" (click)="clearPnr()" name="dp" [(ngModel)]="startDate" class="form-control" (dateTimeInput)="cambiafecha()" [max]="max">
                        <owl-date-time [startAt]="startAt" #dt1></owl-date-time>
                        <button class="btn btn-outline-secondary calendar" [owlDateTimeTrigger]="dt1" (click)="clearPnr()" type="button"><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar-date-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-6.664-1.21c-1.11 0-1.656-.767-1.703-1.407h.683c.043.37.387.82 1.051.82.844 0 1.301-.848 1.305-2.164h-.027c-.153.414-.637.79-1.383.79-.852 0-1.676-.61-1.676-1.77 0-1.137.871-1.809 1.797-1.809 1.172 0 1.953.734 1.953 2.668 0 1.805-.742 2.871-2 2.871zm.066-2.544c.625 0 1.184-.484 1.184-1.18 0-.832-.527-1.23-1.16-1.23-.586 0-1.168.387-1.168 1.21 0 .817.543 1.2 1.144 1.2zm-2.957-2.89v5.332H5.77v-4.61h-.012c-.29.156-.883.52-1.258.777V8.16a12.6 12.6 0 0 1 1.313-.805h.632z"/>
                            </svg>
                        </button>
                    </div>
                        <label for="df" class="col-form-label">Fecha Fin</label>
                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                        <input [owlDateTime]="dt2" placeholder="MM/dd/yyyy, HH:mm" (click)="clearPnr()" name="df" [(ngModel)]="endDate" class="form-control" [min]="min">
                        <owl-date-time [startAt]="endAt" #dt2></owl-date-time>
                        <button class="btn btn-outline-secondary calendar" [owlDateTimeTrigger]="dt2" (click)="clearPnr()" type="button"><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar-date-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-6.664-1.21c-1.11 0-1.656-.767-1.703-1.407h.683c.043.37.387.82 1.051.82.844 0 1.301-.848 1.305-2.164h-.027c-.153.414-.637.79-1.383.79-.852 0-1.676-.61-1.676-1.77 0-1.137.871-1.809 1.797-1.809 1.172 0 1.953.734 1.953 2.668 0 1.805-.742 2.871-2 2.871zm.066-2.544c.625 0 1.184-.484 1.184-1.18 0-.832-.527-1.23-1.16-1.23-.586 0-1.168.387-1.168 1.21 0 .817.543 1.2 1.144 1.2zm-2.957-2.89v5.332H5.77v-4.61h-.012c-.29.156-.883.52-1.258.777V8.16a12.6 12.6 0 0 1 1.313-.805h.632z"/>
                        </svg></button>
                    </div>
                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                        <button class="btn btn-danger" role="button" (click)='findPnrs()' [disabled]="( filterPnr == null && (startDate == null || endDate == null))  ">Buscar</button>
                    </div>
                </div>
            </div>

        </div>
    </form>

    <hr/>
    <div class=" card border-stylish-color-dark  my-5 " [hidden]="listpnrs?.length==0 || listpnrEmitidos?.length>0">
        <div class="card-header text-light  "></div>
        <div class="card-body text-app-color ">
            <h5 class="card-title">Listado de pnr´s</h5>
            <div class="alert alert-info" *ngIf="listpnrs?.length==0">
                No se encontraron resgistros para emitir!
            </div>
            <div class="col-auto">
                <!-- <ul class="text-right list-inline"> -->
                <div class="float-right">
                    <div class="row">
                        <div class="input-group-prepend float-right m-4">
                            Seleccionar todo FP
                            <div class="input-group-text center checkbox-inline ">
                                <input type="checkbox" [(ngModel)]="selectedAllFP" [disabled]="disableAllFP()" (change)="selectAllFP(mdbTablePagination.firstItemIndex);checkIfaItemIsSelectedFP();changeDisableFP($event)" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-group-prepend float-right m-1">
                            Seleccionar todo Emitir
                            <div class="input-group-text center checkbox-inline">
                                <input type="checkbox" [(ngModel)]="selectedAll" [disabled]="disableAllSelect()" (change)="selectAll(mdbTablePagination.firstItemIndex);checkIfaItemIsSelected();changeDisableSelected($event)" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="table-wrapper-scroll-y my-custom-scrollbar"> -->
                <table mdbTable #tableEl="mdbTable" class="table table-bordered table-striped table-hover table-sm table-responsive-lg ">
                    <thead>
                        <tr>
                            <th class="text-center">PNR</th>
                            <th class="text-center">FechaTransacción</th>
                            <th class="text-center">Medio de Pago</th>
                            <th class="text-center">Estado</th>
                            <th class="text-center">TimeToThink</th>
                            <th class="text-center">Lenguaje</th>
                            <th class="text-center">FP</th>
                            <th class="text-center">Emitir</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pnr of listpnrs; let i = index">
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">{{pnr.pnr}}</td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">{{pnr.fechaTransaccion}}</td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">{{pnr.formaDePago}}</td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">{{pnr.estado}}</td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">{{pnr.esTimeToThink}}</td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">{{pnr.lenguaje}}</td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row">
                                <div class="input-group-prepend justify-content-center">
                                    <div class="input-group-text center checkbox-inline">
                                        <input type="checkbox" id="fp_{{i+1}}" [disabled]="pnr.tipoFallo==0 || (pnr.tipoFallo==1 && pnr.blockFP==true) || (pnr.estado.toLocaleLowerCase()=='emitido manual')" [(ngModel)]="pnr.agregarFP" (change)="checkIfAllSelectedFP();checkIfaItemIsSelectedFP();disableSelected($event,pnr)" aria-label="Checkbox for following text input">
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row">
                                <div class="input-group-prepend justify-content-center">
                                    <div class="input-group-text center checkbox-inline">
                                        <input type="checkbox" id="selected_{{i+1}}" [disabled]="emitDisable(pnr)" [(ngModel)]="pnr.selected" (change)="checkIfAllSelected();checkIfaItemIsSelected();disableFPCheck($event,pnr)" aria-label="Checkbox for following text input">
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="grey lighten-5 w-100">
                        <tr>
                            <td colspan="8">
                                <mdb-table-pagination #mdbTablePagination [tableEl]="tableEl" [searchDataSource]="listpnrs"></mdb-table-pagination>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                <!-- </div> -->
                <div class="float-right my-3">
                    <button class="btn btn-danger" role="button" (click)='sendEmisiones()' [disabled]="checkIfCanNotEmit()">Emitir</button>
                </div>
            </div>
        </div>
    </div>

    <div class=" card border-stylish-color-dark  my-5 " [hidden]="listpnrEmitidos?.length==0">
        <div class="card-header text-light  "></div>
        <div class="card-body text-app-color ">
            <h5 class="card-title">Resultado de la Emisión</h5>
            <div class="alert alert-info" *ngIf="listpnrs?.length==0">
                Sin resultados de emisión!
            </div>
            <div class="col-auto">
                <!-- <ul class="text-right list-inline"> -->

                <table mdbTable #tableE2="mdbTable" class="table table-bordered table-striped table-hover table-sm table-responsive-lg ">
                    <thead>
                        <tr>
                            <th class="text-center">PNR</th>
                            <th class="text-center">Apellido</th>
                            <th class="text-center">Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pnr of listpnrEmitidos; let i = index">
                            <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex" scope="row" class="text-center">{{pnr.Pnr}}</td>
                            <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex" scope="row" class="text-center">{{pnr.lasName}}</td>
                            <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex" scope="row" class="text-center">{{pnr.status}}</td>
                        </tr>
                    </tbody>
                    <tfoot class="grey lighten-5 w-100">
                        <tr>
                            <td colspan="4">
                                <mdb-table-pagination #mdbTablePaginationResult [tableEl]="tableE2" [searchDataSource]="listpnrEmitidos"></mdb-table-pagination>
                            </td>
                        </tr>
                    </tfoot>
                </table>

            </div>
        </div>
    </div>
</div>
