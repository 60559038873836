import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map, timeout } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { RequestServiceMedioPago } from '../shared/request-service-mediopago';
import { RequestServiceUpdateMedioPago } from '../shared/request-service-update-mediopago';
import { ResponseServiceUpdateMedioPago } from '../shared/response-service-mediopago';
import { RequestServiceBanco} from '../shared/request-service-banco';

@Injectable({
  providedIn: 'root'
})
export class MedioPagoService {

  private httpHeaders;
  private suscription = environment.ocpSuscription;
  private endpointService = environment.urlService;
  private authorization = environment.authorization;

  constructor(private http: HttpClient) { }


  getListCanalesMedioPago(): Observable<any> {
    this.httpHeaders =
      new HttpHeaders
        ({
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': this.suscription,
          'Authorization': this.authorization,
          'Metodo': 'medios-pago/listas/canales'
        });

    return this.http.get(this.endpointService + "/mediosPago", { headers: this.httpHeaders })
      .pipe(
        map(response => response as any),
        timeout(30000),
        catchError(e => {
          Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
          return throwError(e);
        })
      );
  }

  getListPaisesMedioPago(): Observable<any> {
    this.httpHeaders =
      new HttpHeaders
        ({
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': this.suscription,
          'Authorization': this.authorization,
          'Metodo': 'medios-pago/listas/paises'
        });

    return this.http.get(this.endpointService + "/mediosPago", { headers: this.httpHeaders })
      .pipe(
        map(response => response as any),
        timeout(30000),
        catchError(e => {
          Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
          return throwError(e);
        })
      );
  }

  getListFranquiciasMedioPago(): Observable<any> {
    this.httpHeaders =
      new HttpHeaders
        ({
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': this.suscription,
          'Authorization': this.authorization,
          'Metodo': 'medios-pago/listas/franquicias'
        });

    return this.http.get(this.endpointService + "/mediosPago", { headers: this.httpHeaders })
      .pipe(
        map(response => response as any),
        timeout(30000),
        catchError(e => {
          Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
          return throwError(e);
        })
      );
  }

  getListMedioPagos(): Observable<any> {
    this.httpHeaders =
      new HttpHeaders
        ({
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': this.suscription,
          'Authorization': this.authorization,
          'Metodo': 'medios-pago/listas/medios-pago'
        });

    return this.http.get(this.endpointService + "/mediosPago", { headers: this.httpHeaders })
      .pipe(
        map(response => response as any),
        timeout(30000),
        catchError(e => {
          Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
          return throwError(e);
        })
      );
  }
  
  getParametriaMedioPago(request: RequestServiceMedioPago): Observable<any> {
    this.httpHeaders =
      new HttpHeaders
        ({
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': this.suscription,
          'Authorization': this.authorization
        });

    return this.http.post(this.endpointService + "/mediosPago/consulta", request, { headers: this.httpHeaders })
      .pipe(
        map(response => response as any),
        timeout(30000),
        catchError(e => {
          Swal.fire('Error de comunicación', e.message, 'error');
          return throwError(e);
        })
      );
  }

  updateParametriaMedioPago(request: RequestServiceUpdateMedioPago): Observable<ResponseServiceUpdateMedioPago> {
    this.httpHeaders =
      new HttpHeaders
        ({
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': this.suscription,
          'Authorization': this.authorization
        });

    return this.http.put(this.endpointService+ "/mediosPago/update", request, { headers: this.httpHeaders })
      .pipe(
        map(response => response as ResponseServiceUpdateMedioPago),
        timeout(30000),
        catchError(e => {
          Swal.fire('Error de comunicación', e.message, 'error');
          return throwError(e);
        })
      );
  }

  // Bancos

  getListBancos(): Observable<any> {
    this.httpHeaders =
      new HttpHeaders
        ({
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': this.suscription,
          'Authorization': this.authorization,
          'Metodo': 'bancos'
        });

    return this.http.get(this.endpointService + "/mediosPago", { headers: this.httpHeaders })
      .pipe(
        map(response => response as any),
        timeout(30000),
        catchError(e => {
          Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
          return throwError(e);
        })
      );
  }

  updateListCanalesBanco(request: RequestServiceBanco): Observable<any> {
    this.httpHeaders =
      new HttpHeaders
        ({
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': this.suscription,
          'Authorization': this.authorization
        });

    return this.http.put(this.endpointService+ "/bancos", request, { headers: this.httpHeaders })
      .pipe(
        map(response => response as any),
        timeout(30000),
        catchError(e => {
          Swal.fire('Error de comunicación', e.message, 'error');
          return throwError(e);
        })
      );
  }



}
