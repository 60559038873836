import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { RelanzamientosService } from 'src/app/services/relanzamientos.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-reporting-service',
  templateUrl: './reporting-service.component.html',
  styleUrls: ['./reporting-service.component.css']
})
export class ReportingServiceComponent implements OnInit, AfterViewInit {
  @ViewChild('form') form;
  
  nameFile : String;
  uploadDate : Date;
  today = new Date();
  startAt = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 0, 0);
  urlReportServer = environment.urlReportingService;
  parameters : String [] = ['?NombreArchivo=','&FechaCarga=','?FechaCarga='];
  showParameters: string = "true"; 

  constructor(private relanzamientosService: RelanzamientosService,
    private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService, public datepipe : DatePipe) {
  }

  ngAfterViewInit(): void {
    this.reset();
  }

  ngOnInit(): void {
  }

  reset(): void {
    this.nameFile = undefined;
    this.uploadDate = undefined;
  }

  consultReport(): void {
    if (!this.nameFile && !this.uploadDate) {
      Swal.fire('Ingrese un campo', '', 'info');
    }else{
       let uploadDateSend = this.setDate(this.uploadDate);
       if(!this.nameFile){
        window.open( this.urlReportServer + this.parameters[2] + uploadDateSend, "_blank");
       }else if(!this.uploadDate){
        window.open( this.urlReportServer + this.parameters[0] + this.nameFile, "_blank");
       }else{
        window.open( this.urlReportServer + this.parameters[0] + this.nameFile + this.parameters[1] + uploadDateSend, "_blank");
       }
       Swal.fire('Redirección exitosa', '', 'success');
       this.spinnerService.hide();
       this.reset();
    }
  }

  setDate(oldDateFormat){
    let newDateFormat =this.datepipe.transform(oldDateFormat, 'yyyy/MM/dd');
    return newDateFormat;
  }

}
