export class Pnr {
    pnr: string;
    lastName: string;
    FP: number;
    oid: string;
    lang: string;
    ttt: number;
    paymentMethod: string;
}

export class RequestServiceEmision {
    request: Pnr[];
}
