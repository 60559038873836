export class Pnr {
    pnr: string;
    lastName: string;
    oid: string;
    franquicia: string;
    paymentMethod: string;
    FP: number;
    cus: string;
    lang: string;
    ttt: number;
    codAuth: number;
}

export class RequestGenericEmision {
    request: Pnr[];
}