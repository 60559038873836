<app-navbar></app-navbar>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="container">
    <form class="form-inline my-5">
        <div class="form-group">
            <div class="container ">

                <div class="input-group text-app-color">
                        <label for="pnr" class="col-form-label">Nombre del archivo</label>
                    <div class="input-group-append col-xs-auto col-sm-auto col-md-2">
                        <input type="text" class="form-control col-xs-auto col-sm-auto col-md-auto" name="nameFile" [(ngModel)]="nameFile">
                    </div>
                        <label for="dp" class="col-form-label">Fecha de carga</label>
                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                        <input [owlDateTime]="dt1" placeholder="MM/dd/yyyy" [(ngModel)]="uploadDate" class="form-control" [max]="today" [ngModelOptions]="{standalone: true}">
                        <owl-date-time [pickerType]="'calendar'" [startAt]="startAt" #dt1></owl-date-time>
                        <button class="btn btn-outline-secondary calendar" [owlDateTimeTrigger]="dt1" type="button"><svg width="1em" height="1em" viewBox="0 0 16 16"
                            class="bi bi-calendar-date-fill" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-6.664-1.21c-1.11 0-1.656-.767-1.703-1.407h.683c.043.37.387.82 1.051.82.844 0 1.301-.848 1.305-2.164h-.027c-.153.414-.637.79-1.383.79-.852 0-1.676-.61-1.676-1.77 0-1.137.871-1.809 1.797-1.809 1.172 0 1.953.734 1.953 2.668 0 1.805-.742 2.871-2 2.871zm.066-2.544c.625 0 1.184-.484 1.184-1.18 0-.832-.527-1.23-1.16-1.23-.586 0-1.168.387-1.168 1.21 0 .817.543 1.2 1.144 1.2zm-2.957-2.89v5.332H5.77v-4.61h-.012c-.29.156-.883.52-1.258.777V8.16a12.6 12.6 0 0 1 1.313-.805h.632z" />
                        </svg>
                    </button>
                    </div>
                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                        <button class="btn btn-danger" role="button" (click)='consultReport()' >Consultar reporte</button>
                    </div>
                </div>
            </div>

        </div>
    </form>
</div>