import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { CSVReembolsosWP} from '../../shared/reembolsos-wp-csv';
import { RelanzamientosService } from '../../services/relanzamientos.service';
import { Amount, ReembolsosWP, RequestReembolsosWP } from '../../shared/request-reembolsos-wp';
import { ResponseServiceRelunch, StatusFinal } from '../../shared/response-reembolsos-wp';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import * as Papa from 'papaparse';
import { isNull } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-reembolsos-worldpay',
  templateUrl: './reembolsos-worldpay.component.html',
  styleUrls: ['./reembolsos-worldpay.component.css']
})
export class ReembolsosWorldpayComponent implements OnInit, AfterViewInit {
  @ViewChild('mdbTablePagination', { static: true })  mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('mdbTablePaginationResult', { static: true })  mdbTablePaginationResult: MdbTablePaginationComponent;
  @ViewChild('tableEl', { static: true }) tableEl: MdbTableDirective;
  @ViewChild('tableE2', { static: true }) tableE2: MdbTableDirective;
  @Output() newItemEvent = new EventEmitter<RequestReembolsosWP>();

  listReembolsos : CSVReembolsosWP[]=[];
  requestReembolsoWP : RequestReembolsosWP;
  resultRefunds: ResponseServiceRelunch;
  filterPnr: string = '';
  filterCanal: string = '';
  fileField: File[];
  selectedAll: any;
  blockAllFP: boolean;
  listFilter : CSVReembolsosWP[]=[];
  listStatusFinal: StatusFinal[]=[]
  @ViewChild('form') form;
  previous: any = [];
  selectedAItem: boolean;

  constructor(private relanzamientosService: RelanzamientosService, private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.requestReembolsoWP = new RequestReembolsosWP();
    this.requestReembolsoWP.request = [];
    this.selectedAItem = false;
   }

  ngOnInit(): void {
  }

  onChange(files: File[]){
    this.listReembolsos = [];
    this.listStatusFinal = [];
    this.listFilter = [];
    var ext =this.validaFile(files);
    if (ext){
      if(files[0]){
        this.spinnerService.show();
        Papa.parse(files[0], {
          header: true,
          skipEmptyLines: true,
          complete: (result,file) => {
            var cabeceras = this.validaCabeceras(result.meta.fields);
            if(cabeceras){
              this.listReembolsos = result.data;
              this.listFilter = result.data;
              if(this.listReembolsos.length != 0){
                this.configureTable();
                this.spinnerService.hide();
                this.reset();
                this.clearCheckbox();
                this.filterCanal = '';
                this.filterPnr = '';
              }else{
                this.spinnerService.hide();
                this.reset();
              }
            }
            else{
              Swal.fire('El archivo no cuenta con las cabeceras correspondientes, favor de validar', '', 'info');
              this.spinnerService.hide();
              this.reset();
            }
          }
        });
      }
    }
    else{
      Swal.fire('El archivo no es de tipo csv, intente con otro archivo', '', 'info');
      this.spinnerService.hide();
      this.reset();
    }
  }
  
  configureTable(): void {
    this.listStatusFinal = [];
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);
    this.tableEl.setDataSource(this.listReembolsos);
    this.listReembolsos = this.tableEl.getDataSource();
    this.previous = this.tableEl.getDataSource();
    
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.mdbTablePagination.firstPage();
    this.cdRef.detectChanges();
  }

  reset() {
    this.form.nativeElement.reset()
  }

  validaFile(files: File[]){
    if (files[0].name.includes('.csv') || files[0].name.includes('.CSV')){
      return true;
    }
    else{
      return false;
    }
  }

  validaCabeceras(file:any[]){
    if (file.includes('codigoPais') && file.includes('canal') && file.includes('descripcion') && file.includes('referencia') && file.includes('moneda') && file.includes('monto') ){
      return true;
    }
    else{
      return false;
    }
  }

  fieldsChange(): void {
    if((this.filterCanal === '' || null) && (this.filterPnr === '' || null)){
      this.filterCanal = '';
      this.filterPnr = '';
      this.listReembolsos = this.listFilter;
      this.clearCheckbox();
      this.configureTable();
    }
  }

  filterList(): void {
    this.spinnerService.show();
    if(this.filterCanal != '' || null){
      this.listReembolsos = this.listFilter.filter((find) => find.canal.toLowerCase().includes(this.filterCanal.toLowerCase()));
      if(this.listReembolsos.length === 0){
        this.filterCanal = '';
        this.filterPnr = '';
        Swal.fire('La búsqueda no arrojo resultados', 'Se muestran datos iniciales.', 'info');
        this.listReembolsos = this.listFilter;
      }else if(this.filterPnr != '' || null){
        this.listReembolsos = this.listReembolsos.filter((find) => find.referencia.toLowerCase().includes(this.filterPnr.toLowerCase()));
        if(this.listReembolsos.length === 0){
          this.filterCanal = '';
          this.filterPnr = '';
          Swal.fire('La búsqueda no arrojo resultados', 'Se muestran datos iniciales.', 'info');
          this.listReembolsos = this.listFilter;
        }
      }
    }else{
      this.listReembolsos = this.listFilter.filter((find) => find.referencia.toLowerCase().includes(this.filterPnr.toLowerCase()));
      if(this.listReembolsos.length === 0){
        this.filterCanal = '';
        this.filterPnr = '';
        Swal.fire('La búsqueda no arrojo resultados', 'Se muestran datos iniciales.', 'info');
        this.listReembolsos = this.listFilter;
      }
    }
    this.clearCheckbox()
    this.configureTable();
    this.spinnerService.hide();
  }

  selectAll(itemPaginationInicial: number, itemPaginationFinal: number): void {
    for (let i = itemPaginationInicial - 1; i <= itemPaginationFinal - 1; i++) {
      this.listReembolsos[i].selected = this.selectedAll;
    }
  }

  changeDisableSelected(event: any) {
    this.blockAllFP = event.target.checked;
  }

  checkIfAllSelected(): void {
    this.selectedAll = this.listReembolsos.every(function (item: any): boolean {
      return item.selected === true;
    });

    this.blockAllFP = this.selectedAll;
  }

  clearCheckbox(): void{
    this.selectedAll = false;
    this.blockAllFP = false;
    this.selectAll(1, this.listReembolsos.length);
  }
  
  checkIfaItemIsSelected(): boolean {
    for(const item of this.listReembolsos){
      if(item.selected){
        this.selectedAItem = true;
        return false;
      }
    }
    this.selectedAItem = false;
    return true;
  }

  getListRefundsWP(): ReembolsosWP[] {
    let listRefundsSelected: ReembolsosWP[] = [],
        exponent: number;
    for(const item of this.listReembolsos){
      const refundsSelected = new ReembolsosWP();
      refundsSelected.monto = new Amount();
      if(item.selected){
        if(item.monto.includes(',')){
          exponent = (item.monto.length - item.monto.indexOf(',', 0)) - 1;
          item.monto = item.monto.replace(',','');
        }else{
          exponent = 0;
        }
        refundsSelected.pais = item.codigoPais;
        refundsSelected.canal = item.canal;
        refundsSelected.descripcion = item.descripcion;
        refundsSelected.pnr = item.referencia;
        refundsSelected.monto.moneda = item.moneda;
        refundsSelected.monto.exponente = exponent;
        refundsSelected.monto.valor = parseInt(item.monto);
        listRefundsSelected.push(refundsSelected);
      }
    }
    return listRefundsSelected;
  }

  sendRefundsWP(): void {
    let mensaje: string ="Fallido: ";
    this.spinnerService.show();
    this.previous = [];
    this.requestReembolsoWP.request = this.getListRefundsWP();
    this.relanzamientosService.sendRefundsWP(this.requestReembolsoWP).subscribe(res => {
      this.resultRefunds = res;

      this.resultRefunds.Failed.forEach(element => {
        let statusFinal: StatusFinal = new StatusFinal();
        statusFinal.pnr = element.pnr;
        statusFinal.estatus = mensaje.concat(element.message);
        this.listStatusFinal.push(statusFinal);
      });
      this.resultRefunds.Success.forEach(element => {
        let statusFinal: StatusFinal = new StatusFinal();
        statusFinal.canal = element.canal;
        statusFinal.pnr = element.pnr;
        statusFinal.estatus = 'Reembolso exitoso';
        this.listStatusFinal.push(statusFinal);
      });
      this.configureTableResult();
      this.spinnerService.hide();
    }, err => {
      console.log(err);
      Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
      this.spinnerService.hide();
    });
  }

  configureTableResult(): void {
    this.listReembolsos = [];
    this.mdbTablePaginationResult.setMaxVisibleItemsNumberTo(10);
    this.tableE2.setDataSource(this.listStatusFinal);
    this.listStatusFinal = this.tableE2.getDataSource();
    this.previous = this.tableE2.getDataSource();
    
    this.mdbTablePaginationResult.calculateFirstItemIndex();
    this.mdbTablePaginationResult.calculateLastItemIndex();
    this.mdbTablePaginationResult.firstPage();
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {
  }

}
