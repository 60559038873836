<app-navbar></app-navbar>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="container">

    <form class="form-inline my-5" >
        <div class="form-group" [hidden]="franquicias?.length == 0" >
            <div class="container ">
                <div class="input-group text-app-color">
                    <div class="col-xs-auto col-sm-auto col-md-3">
                        <label for="canal" class="col-form-label">Canal</label>
                        <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                            <select type="text" class="form-control col-xs-auto col-sm-auto col-md-12" name="canal" [(ngModel)]="canalValue" (ngModelChange)="SeleccionaCanal()"> 
                                <option *ngFor="let canal of canales" [value]="canal.id"> {{canal.value}} </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xs-auto col-sm-auto col-md-3">
                        <label for="pais" class="col-form-label">País</label>
                        <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                            <select type="text" class="form-control col-xs-auto col-sm-auto col-md-12" [disabled]="!canalSelected ? true : false" name="pais" [(ngModel)]="paisValue"> 
                                <option *ngFor="let pais of paises" [value]="pais.id"> {{pais.value}} </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xs-auto col-sm-auto col-md-3">
                        <label for="medioPago" class="col-form-label">Medio de pago</label>
                        <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                            <select type="text" class="form-control col-xs-auto col-sm-auto col-md-12" [disabled]="!canalSelected ? true : false" name="medioPago" [(ngModel)]="medioPagoValue"> 
                                <option *ngFor="let medioPago of mediosPago" [value]="medioPago.id"> {{medioPago.value}} </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xs-auto col-sm-auto col-md-3">
                        <label for="franquicia" class="col-form-label">Franquicia</label>
                        <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                            <select type="text" class="form-control col-xs-auto col-sm-auto col-md-12" [disabled]="!canalSelected ? true : false" name="franquicia" [(ngModel)]="franquiciaValue"> 
                                <option *ngFor="let franquicia of franquicias" [value]="franquicia.id"> {{franquicia.value}} </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xs-auto col-sm-auto col-md-12">
                        <label for="" class="col-form-label">&nbsp;</label>
                        <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                            <button class="btn btn-danger" role="button" (click)='SearchMedioPago()'
                                [disabled]="!canalSelected ? true : false">
                                Consultar
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </form>

    <div class="alert alert-info" *ngIf="listMediosPago?.length == 0 && consultandoData == false && franquicias.length != 0">
        No se encontraron coincidencias
     </div>

    <div [hidden]="listMediosPago?.length == 0 && consultandoData == false"> 
        <div class="card border-stylish-color-dark my-5" >
        <div class="card-header text-light"></div>
        <div class="card-body text-app-color">
            <h5 class="card-title">Listado de Medios de Pago</h5>

            
            <div class="col-auto">
                <div class="float-right">
                </div>

                <table mdbTable #tableEl="mdbTable" class="table table-bordered table-striped table-hover table-sm table-responsive-lg ">
                    <thead>
                        <tr>
                            <th class="text-center font-weight-bold">Canal</th>
                            <th class="text-center font-weight-bold">País</th>
                            <th class="text-center font-weight-bold">Medio de pago</th>
                            <th class="text-center font-weight-bold">Franquicia</th>                        
                            <th class="text-center font-weight-bold">Medio de pago presencial</th>
                            <th class="text-center font-weight-bold">Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let medioPago of listMediosPago">
                            <td scope="row" class="text-center">{{medioPago.canal}}</td>
                            <td scope="row" class="text-center">{{medioPago.pais}}</td>
                            <td scope="row" class="text-center">{{medioPago.medioDePago}}</td>    
                            <td scope="row" class="text-center">{{medioPago.franquicia == null ? "-" : medioPago.franquicia}}</td>
                            <td scope="row" class="text-center">{{medioPago.medioDePagoPresencial == null ? "-" : medioPago.medioDePagoPresencial}}</td>
                            <td scope="row" class="text-center">
                                <input type="checkbox" [checked]="medioPago.estado" 
                                [id]="medioPago.idRelacionalProcesoPago"
                                (change)="AgregarCambioMedioPago($event)"
                                >
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- </div> -->
                <div class="float-right my-3">
                    <button class="btn btn-danger" role="button" (click)='UpdateMedioPago()'
                        [disabled]="cambiosHechosList.length <= 0 ? true : false">
                        Actualizar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>