export class Banco {
    codigo: string;
    nombre: string;
    bloqueadoEnCanales: string[];
}

export class ResponseServiceBancosPse {
    data: Banco[];
}

