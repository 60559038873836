<app-navbar></app-navbar>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="container">
                
  <div class="my-3 text-app-color">
    <h5 class="card-title">HISTORIAL DE LOGS</h5>
  </div>

  <hr/>

  <form class="form-inline my-5">
    <div class="form-group">
        <div class="container">
            <div class="input-group text-app-color">
                <div class="col-xs-auto col-sm-auto col-md-4">
                    <label for="canal" class="col-form-label">Usuario</label>
                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                      <input type="text" class="form-control col-xs-12 col-sm-12 col-md-12" name="usuario"
                      [(ngModel)]="filterUser" #userfilter="ngModel">
                    </div>
                </div>
                <div class="col-xs-auto col-sm-auto col-md-4">
                    <label for="pais" class="col-form-label">Fecha Inicio</label>
                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                      <input [owlDateTime]="dt1" placeholder="MM/dd/yyyy, HH:mm" name="dp"
                      [(ngModel)]="startDate" class="form-control" (dateTimeInput)="cambiafecha()" [max]="max">
                      <owl-date-time [startAt]="startAt" #dt1></owl-date-time>
                      <button class="btn btn-outline-secondary calendar" [owlDateTimeTrigger]="dt1" type="button">
                        <svg width="1em" height="1em" viewBox="0 0 16 16"
                        class="bi bi-calendar-date-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd"
                              d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-6.664-1.21c-1.11 0-1.656-.767-1.703-1.407h.683c.043.37.387.82 1.051.82.844 0 1.301-.848 1.305-2.164h-.027c-.153.414-.637.79-1.383.79-.852 0-1.676-.61-1.676-1.77 0-1.137.871-1.809 1.797-1.809 1.172 0 1.953.734 1.953 2.668 0 1.805-.742 2.871-2 2.871zm.066-2.544c.625 0 1.184-.484 1.184-1.18 0-.832-.527-1.23-1.16-1.23-.586 0-1.168.387-1.168 1.21 0 .817.543 1.2 1.144 1.2zm-2.957-2.89v5.332H5.77v-4.61h-.012c-.29.156-.883.52-1.258.777V8.16a12.6 12.6 0 0 1 1.313-.805h.632z" />
                        </svg>
                      </button>
                    </div>
                </div>
                <div class="col-xs-auto col-sm-auto col-md-4">
                    <label for="medioPago" class="col-form-label">Fecha Fin</label>
                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                      <input [owlDateTime]="dt2" placeholder="MM/dd/yyyy, HH:mm" name="df" 
                      [(ngModel)]="endDate" class="form-control" [min]="min">
                      <owl-date-time [startAt]="endAt" #dt2></owl-date-time>
                      <button class="btn btn-outline-secondary calendar" [owlDateTimeTrigger]="dt2" type="button">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar-date-fill"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd"
                                  d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-6.664-1.21c-1.11 0-1.656-.767-1.703-1.407h.683c.043.37.387.82 1.051.82.844 0 1.301-.848 1.305-2.164h-.027c-.153.414-.637.79-1.383.79-.852 0-1.676-.61-1.676-1.77 0-1.137.871-1.809 1.797-1.809 1.172 0 1.953.734 1.953 2.668 0 1.805-.742 2.871-2 2.871zm.066-2.544c.625 0 1.184-.484 1.184-1.18 0-.832-.527-1.23-1.16-1.23-.586 0-1.168.387-1.168 1.21 0 .817.543 1.2 1.144 1.2zm-2.957-2.89v5.332H5.77v-4.61h-.012c-.29.156-.883.52-1.258.777V8.16a12.6 12.6 0 0 1 1.313-.805h.632z" />
                        </svg>
                      </button>
                    </div>
                </div>
                <div class="col-xs-auto col-sm-auto col-md-12">
                    <label for="" class="col-form-label">&nbsp;</label>
                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                        <button class="btn btn-danger" role="button" (click)='searchLogs()'
                        [disabled]="buttonDisabled()">
                            Consultar
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
  </form>

  <hr/>  

  <div class="card border-stylish-color-dark my-5" [hidden]="listLogs?.length == 0">
    <div class="card-header text-light"></div>
    <div class="card-body text-app-color">
        <h5 class="card-title">Tabla de resultados</h5>
        <div class="col-auto">
            <table mdbTable #tableEl="mdbTable"
                class="table table-bordered table-striped table-hover table-sm table-responsive-lg">
                <thead>
                    <tr>
                        <th class="text-center"><b>ID DE SEGUIMIENTO</b></th>
                        <th class="text-center"><b>USUARIO</b></th>
                        <th class="text-center"><b>OPERACIÓN</b></th>
                        <th class="text-center"><b>FECHA DE ACTUALIZACIÓN</b></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of listLogs; let i = index">
                        <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex"
                            class="text-center">{{item.idSeguimiento}}</td>
                        <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex"
                            class="text-center">{{item.usuario}}</td>
                        <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex"
                            class="text-center">{{item.operacion}}</td>
                        <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex"
                            class="text-center">{{item.fechaActualizacion}}</td>
                    </tr>
                </tbody>
                <tfoot class="grey lighten-5 w-100">
                    <tr>
                        <td colspan="4">
                            <mdb-table-pagination #mdbTablePaginationResult [tableEl]="tableEl"
                                [searchDataSource]="listLogs"></mdb-table-pagination>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
  </div>
  
</div>