export class PnrsCancelarUatp {
    pnr: string;
    numAutorization:string;
    fechaTransaccion: string;
    selected: boolean | false;
    

}

export class Response {

    pnrsCancelarUatp: PnrsCancelarUatp[];
}
