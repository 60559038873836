import {Pnr} from './requestServiceDuplex';

export class ResponseServiceDuplex {
    Success: Success[];
    Failed: Failed[];
    Warning: Warning[];
}

export class Success {
    pnr : string;
    comment: string;
}

export class Failed {
    pnr: string;
    comment: string;
    error: Error;
}

export class Warning {
    pnr: string;
    comment: string;
    error: Error;
}

export class Error {
    message: string;
}

export class PnrStateful {
    Pnr: string;
    comment: string;
    status: string;
    reintento: boolean | false;
}
