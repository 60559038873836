<app-navbar></app-navbar>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class=" container">
                
  <div class="my-3 text-app-color">
    <h5 class="card-title">GESTIÓN DE MÓDULOS</h5>
  </div>

  <hr/>

  <div class ="container">
    <div class="row" cdkDropListGroup>
      <div class="col-xs-auto col-sm-4 col-md-4">
        <h4>Módulos de gestión</h4>
        <div
          cdkDropList
          [cdkDropListData]="modulosGestion"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of modulosGestion" cdkDrag>{{item}}
            <button class="btn btn-danger" role="button" [disabled]="" (click)="cloneItem(item)">+</button></div>
        </div>
      </div>
        
      <div class="col-xs-auto col-sm-4 col-md-4">
        <h4>Grupo monitoreo</h4>
        <div
          cdkDropList
          [cdkDropListData]="monitoreo"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of monitoreo" cdkDrag>{{item}}</div>
        </div>
      </div>

      <div class="col-xs-auto col-sm-4 col-md-4">
        <h4>Grupo medios de pago</h4>
        <div
          cdkDropList
          [cdkDropListData]="mediosPago"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of mediosPago" cdkDrag>{{item}}</div>
        </div>
      </div>

    </div> 
  </div>

  <hr/>

  <div class ="container">
    <div class="input-group-append my-3 float-right">
        <button class="btn btn-danger" role="button" [disabled]="" (click)="gestionarPermisos()">Gestionar módulos</button>
    </div>
  </div>  
</div>