import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { RelanzamientosService } from '../../services/relanzamientos.service';
import { Duplex } from '../../shared/duplex';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { Router, ActivatedRoute } from '@angular/router';
import { RequestServiceDuplex, Pnr } from '../../shared/requestServiceDuplex';
import { ResponseServiceDuplex, Success, Failed, PnrStateful } from '../../shared/responseServiceDuplex';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatDate, UpperCasePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { stringify } from '@angular/compiler/src/util';
import { RequestSearchBooking } from 'src/app/shared/request-search-booking';
import { ResponseDuplex } from 'src/app/shared/response-duplex';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { concat } from 'rxjs';


@Component({
  selector: 'app-duplex',
  templateUrl: './duplex.component.html',
  styleUrls: ['./duplex.component.css']
})
export class DuplexComponent implements OnInit, AfterViewInit {
  @ViewChild('mdbTablePagination', { static: true })  mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('mdbTablePaginationResult', { static: true })  mdbTablePaginationResult: MdbTablePaginationComponent;
  @ViewChild('tableEl', { static: true }) tableEl: MdbTableDirective;
  @ViewChild('tableE2', { static: true }) tableE2: MdbTableDirective;
  @Output() newItemEvent = new EventEmitter<RequestServiceDuplex>();
  previous: any = [];
  requestServiceDuplex: RequestServiceDuplex;
  requestSearchBooking: RequestSearchBooking;
  resultDuplex: ResponseServiceDuplex;
  listPnrSucces: Success;
  listPnrFallidos: Failed;
  listpnrEliminados: PnrStateful[] = [];
  duplex:  Duplex;
  listpnrs: Duplex[] = [];
  date: string;
  fecha1: Date;
  fecha2: Date;
  fechaLocal: any;
  opcion:string = "";
  opcion1:boolean = false;
  opcion2: boolean = false;
  opcion3:boolean = false;
  lastName: string = "";
  name1: string;
  oid: string;
  name2: string;
  name3: string;
  vuelo: string;
  mensaje: string = '';
  selectedAItem: boolean;
  selectedAItem2: boolean;


  constructor(private relanzamientosService: RelanzamientosService, private cdRef: ChangeDetectorRef, private router: Router, private activatedRoute: ActivatedRoute, private spinnerService: NgxSpinnerService) {
    this.selectedAItem = false;
    this.selectedAItem2 = false;
    this.requestServiceDuplex = new RequestServiceDuplex();
    this.requestServiceDuplex.request = [];
    this.requestSearchBooking = new RequestSearchBooking();
  }

  ngOnInit(): void {
  }

  
  configureTableResult(): void {
    this.mdbTablePaginationResult.setMaxVisibleItemsNumberTo(10);
    this.tableE2.setDataSource(this.listpnrEliminados);
    this.listpnrEliminados = this.tableE2.getDataSource();
    this.previous = this.tableE2.getDataSource();

    this.mdbTablePaginationResult.calculateFirstItemIndex();
    this.mdbTablePaginationResult.calculateLastItemIndex();
    this.mdbTablePaginationResult.firstPage();
    this.cdRef.detectChanges();
  }

  configureTable(): void {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);
    this.tableEl.setDataSource(this.listpnrs);
    this.listpnrs = this.tableEl.getDataSource();
    this.previous = this.tableEl.getDataSource();

    
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.mdbTablePagination.firstPage();
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {

  }

  limpiaOpcion(event:any): void {
    if(event.target.id == "rdFiltro1"){
      if(event.target.checked){
        this.opcion1 = true;
        this.opcion = "1";
        this.opcion2 = false;
        this.opcion3 = false;
        this.name2 = null;
        this.name3 = null;
        this.vuelo = null;
        this.fecha1 = null;
        this.fecha2 = null;
        this.listpnrs = [];
        this.listpnrEliminados= [];
      }
    }

    if(event.target.id == "rdFiltro2"){
      if(event.target.checked){
        this.opcion1 = false;
        this.opcion2 = true;
        this.opcion = "2";
        this.opcion3 = false;
        this.name1 = null;
        this.oid = null;
        this.name3 = null;
        this.vuelo = null;
        this.fecha2 = null;
        this.listpnrs = [];
        this.listpnrEliminados= [];
      }
    }
    
    if(event.target.id == "rdFiltro3"){
      if(event.target.checked){
        this.opcion1 = false;
        this.opcion2 = false;
        this.opcion3 = true;
        this.opcion = "3";
        this.name1 = null;
        this.oid = null;
        this.name2 = null;
        this.fecha1 = null;
        this.listpnrs = [];
        this.listpnrEliminados= [];
      }
    }
  }

  validaOpcion(){
    if (this.opcion1){
      if(this.name1 == null || this.oid == null){
        this.mensaje = 'Falta agregar el Apellido y/o el OfficeID, favor de validar';
      }
    }
    if (this.opcion2){
      if(this.name2 == null || this.fecha1 == null){
        this.mensaje = 'Falta agregar el Apellido y/o la fecha, favor de validar';
      }
    }
    if (this.opcion3){
      if(this.name3 == null || this.fecha2 == null || this.vuelo == null){
        this.mensaje = 'Falta agregar el Apellido, la fecha y/o numero de vuelo, favor de validar';
      }
    }

  }

  findDuplex(){
    this.mensaje = '';
    this.listpnrs = [];
    this.listpnrEliminados= [];
    this.selectedAItem = false;
    this.selectedAItem2 = false;
    this.spinnerService.show();
    this.validaOpcion();
    if (this.mensaje != ''){
      Swal.fire(this.mensaje, '', 'info');
        this.spinnerService.hide();
    }
    else{
      this.listpnrs = [];
      this.requestSearchBooking.filter = this.opcion;
      switch (this.opcion) {
        case "1":
          this.lastName = this.name1;
          break;
        case "2":
          this.lastName = this.name2;
          this.date = formatDate(this.fecha1, 'dd/MMM', 'en-US').replace('/', '');
          break;
        case "3":
          this.lastName = this.name3;
          this.date = formatDate(this.fecha2, 'dd/MMM', 'en-US').replace('/', '');
          break;
        default:
          this.lastName = null;
          break;
      }
      this.requestSearchBooking.lastName = this.lastName;
      this.requestSearchBooking.officeId = this.oid;
      this.requestSearchBooking.date = this.date;
      this.requestSearchBooking.flightNumber = this.vuelo;
      this.relanzamientosService.searchBooking(this.requestSearchBooking)
      .subscribe(booking => {
        if(booking != null){
          if(booking.data.mensaje != null){
            let mensajeNoDuplicado = 'Reserva: ' + booking.data.reserva + '\n' + 'Nombre: ' + booking.data.nombre  + '\n' + 'Mensaje: ' + booking.data.mensaje;
            Swal.fire(mensajeNoDuplicado, '', 'info');
            this.spinnerService.hide();
          }
          else{
            booking.data.forEach(element => {
              const response = new Duplex();
              response.aerolinea = element.aerolinea;
              response.cantidad = element.cantidad;
              response.clase = element.clase;
              response.fecha = element.fecha;
              response.nombre = element.nombre;
              response.registro = Number(element.numeroDeReserva);
              response.reserva = element.reserva;
              response.ruta = element.ruta;
              response.vuelo = element.vuelo;
              response.selected = false;
              response.eliminar = false;
              response.notas = '';
              this.listpnrs.push(response);
            });
            this.configureTable();
            this.spinnerService.hide();
          }
        }
        else{
          Swal.fire('La búsqueda no arrojo resultados', '', 'info');
        }
      }, err => {
        console.log(err);
        this.spinnerService.hide();
      })
    }
      
    
  }

  checkIfaItemIsSelected(): void {

    for (const pnr of this.listpnrs) {
      if (pnr.eliminar) {
        this.selectedAItem = true;
        return;
      }
    }
    this.selectedAItem = false;
  }

  checkIfaItemIsSelected2(): void {

    for (const pnr of this.listpnrEliminados) {
      if (pnr.reintento) {
        this.selectedAItem2 = true;
        return;
      }
    }
    this.selectedAItem2 = false;
  }

  checkIfCanRetry(): boolean {
    let select: boolean = true;
    for (const pnr of this.listpnrEliminados) {
      if (pnr.status.includes('Precaución: ')) {
        select = false;
        return select;
      }
    }
    return true;
  }

  checkIfCanNotEmit():boolean
   {
     if(this.selectedAItem == true)
       return false;
     else
       return true;
   }

   checkIfCanNotRetry():boolean
   {
     if(this.selectedAItem2 == true)
       return false;
     else
       return true;
   }

  getListPnrSelected(): Pnr[] {

    let listPnrSelected: Pnr[] = [];

    for (const item of this.listpnrs) {
      const pnrSelected = new Pnr();
      console.log(item.reserva + ' ' + item.notas);
      if (item.eliminar === true || item.notas != '') {
        pnrSelected.pnr = item.reserva;
        pnrSelected.comment = item.notas;
        listPnrSelected.push(pnrSelected);

      }
    }
    return listPnrSelected;
  }

  getListPnrSelectedRetry(): Pnr[] {

    let listPnrSelected: Pnr[] = [];

    for (const item of this.listpnrEliminados) {
      const pnrSelected = new Pnr();
      console.log(item.Pnr + ' ' + item.comment);
      if (item.reintento == true) {
        pnrSelected.pnr = item.Pnr;
        pnrSelected.comment = item.comment;
        listPnrSelected.push(pnrSelected);

      }
    }
    return listPnrSelected;
  }

  sendEliminacion(): void {
    let mensaje: string ="Fallido: ";
    this.spinnerService.show();
    this.requestServiceDuplex.request = this.getListPnrSelected();
    this.relanzamientosService.sendElimnaDuplex(this.requestServiceDuplex).subscribe(response => {

      this.resultDuplex = response;

      this.resultDuplex.Failed.forEach(element => {
        let statusPnr:PnrStateful = new PnrStateful();
        statusPnr.Pnr = element.pnr;
        statusPnr.comment = element.comment;
        statusPnr.status = mensaje.concat(element.error.message);
        this.listpnrEliminados.push(statusPnr);
      });

      this.resultDuplex.Success.forEach(element => {
        let statusPnr: PnrStateful = new PnrStateful();
        statusPnr.Pnr = element.pnr;
        statusPnr.comment = element.comment;
        statusPnr.status = 'Eliminado';
        this.listpnrEliminados.push(statusPnr);
      });

      this.resultDuplex.Warning.forEach(element => {
        let statusPnr:PnrStateful = new PnrStateful();
        statusPnr.Pnr = element.pnr;
        statusPnr.comment = element.comment;
        mensaje = 'Precaución: ';
        statusPnr.status = mensaje.concat(element.error.message);
        this.listpnrEliminados.push(statusPnr);
      });

      console.log(this.listpnrEliminados);
      this.configureTableResult();
      this.spinnerService.hide();
    }, err =>{
      console.log(err);
      this.spinnerService.hide();
    });

  }

  retryEliminacion(): void {
    let mensaje: string ="Fallido: ";
    this.spinnerService.show();
    this.requestServiceDuplex.request = this.getListPnrSelectedRetry();
    this.listpnrEliminados= [];
    this.relanzamientosService.sendElimnaDuplex(this.requestServiceDuplex).subscribe(response => {

      this.resultDuplex = response;

      this.resultDuplex.Failed.forEach(element => {
        let statusPnr:PnrStateful = new PnrStateful();
        statusPnr.Pnr = element.pnr;
        statusPnr.comment = element.comment;
        statusPnr.status = mensaje.concat(element.error.message);
        this.listpnrEliminados.push(statusPnr);
      });

      this.resultDuplex.Success.forEach(element => {
        let statusPnr: PnrStateful = new PnrStateful();
        statusPnr.Pnr = element.pnr;
        statusPnr.comment = element.comment;
        statusPnr.status = 'Eliminado';
        this.listpnrEliminados.push(statusPnr);
      });

      this.resultDuplex.Warning.forEach(element => {
        let statusPnr:PnrStateful = new PnrStateful();
        statusPnr.Pnr = element.pnr;
        statusPnr.comment = element.comment;
        mensaje = 'Precaución: ';
        statusPnr.status = mensaje.concat(element.error.message);
        this.listpnrEliminados.push(statusPnr);
      });

      console.log(this.listpnrEliminados);
      this.configureTableResult();
      this.spinnerService.hide();
    }, err =>{
      console.log(err);
      this.spinnerService.hide();
    });

  }

}