import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { RelanzamientosComponent } from './components/relanzamientos/relanzamientos.component';
import { TarjetasGenericasComponent } from './components/tarjetas-genericas/tarjetas-genericas.component';
import { AppComponent } from './app.component';
import { RequestSearchService } from './shared/requestSearchService';
import { DuplexComponent } from './components/duplex/duplex.component';
import { FpGenericasComponent } from './components/fp-genericas/fp-genericas.component';
import { PurgadasComponent } from './components/purgadas/purgadas.component';
import { ReportingServiceComponent} from './components/reporting-service/reporting-service.component';
import { TicketingComponent} from './components/ticketing/ticketing.component';
import { CancelacionesUatpComponent } from './components/cancelaciones-uatp/cancelaciones-uatp.component';
import { WorldpayAuthVoidComponent } from './components/worldpay-auth-void/worldpay-auth-void.component';
import { MediosPagoGestionComponent } from './components/medios-pago-gestion/medios-pago-gestion.component';
import { BancosPSEGestionComponent } from './components/bancos-pse-gestion/bancos-pse-gestion.component';
import { ReembolsosWorldpayComponent } from './components/reembolsos-worldpay/reembolsos-worldpay.component';
import { GestionModulosComponent } from './components/gestion-modulos/gestion-modulos.component';
import { HistorialLogsComponent } from './components/historial-logs/historial-logs.component';
import { VisionComponent } from './components/vision/vision.component';
import { AuthGuardGuard } from './components/guards/auth-guard.guard';

const routes: Routes = [
  {path: 'home', component: HomeComponent },
  {path: 'relanzamientos', component: RelanzamientosComponent, canActivate: [AuthGuardGuard] },
  {path: 'tarjetaGenerica', component: TarjetasGenericasComponent, canActivate: [AuthGuardGuard] },
  {path: 'duplex', component: DuplexComponent, canActivate: [AuthGuardGuard] },
  {path: 'purgadas', component: PurgadasComponent, canActivate: [AuthGuardGuard] },
  {path: 'EmisionesFPGenerica', component : FpGenericasComponent, canActivate: [AuthGuardGuard] },
  {path: 'archivoCargado', component : ReportingServiceComponent, canActivate: [AuthGuardGuard] },
  {path: 'adicionarTKXL', component : TicketingComponent, canActivate: [AuthGuardGuard] },
  {path: 'CancelacionesUatp', component : CancelacionesUatpComponent, canActivate: [AuthGuardGuard] },
  {path: 'AutorizacionesWorldpay', component : WorldpayAuthVoidComponent, canActivate: [AuthGuardGuard] },
  {path: 'medios-pago-gestion', component : MediosPagoGestionComponent, canActivate: [AuthGuardGuard] },
  {path: 'bancos-pse-gestion', component : BancosPSEGestionComponent, canActivate: [AuthGuardGuard] },
  {path: 'reembolsosWorldpay', component : ReembolsosWorldpayComponent, canActivate: [AuthGuardGuard] },
  {path: 'gestionModulos', component : GestionModulosComponent, canActivate: [AuthGuardGuard] },
  {path: 'historialLogs', component : HistorialLogsComponent, canActivate: [AuthGuardGuard] },
  {path: 'modulo-externo/vision', component : VisionComponent },
  {path: '**', pathMatch: 'full', redirectTo: 'home'}

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
