import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { ResponseServiceBancosPse, Banco} from '../../shared/response-service-bancospse';
import { RequestServiceBanco} from '../../shared/request-service-banco';
import { MedioPagoService } from '../../services/mediopago.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bancos-pse-gestion',
  templateUrl: './bancos-pse-gestion.component.html',
  styleUrls: ['./bancos-pse-gestion.component.css']
})
export class BancosPSEGestionComponent implements OnInit {

  @ViewChild('myModalClose') modalClose;

  public bancos: Banco[] = [];
  public canalesBancoSeleccionado: CanalObject[] = [];

  public canales: MedioPagoObject[] = [];
  public cambiosHechosList: string[] = [];

  public bancoSeleccionado: string;
  public cambiosHechos: boolean = false;

  private requestUpdateBanco: RequestServiceBanco;

  constructor(private serviceMedioPago: MedioPagoService) {
    this.requestUpdateBanco = new RequestServiceBanco(); 
    this.cambiosHechosList = [];
  }

  ngOnInit(): void {

    this.GetListBancos();

    this.serviceMedioPago.getListCanalesMedioPago()
      .subscribe(res => {
        res.data.forEach(element => {
          let canal: MedioPagoObject = new MedioPagoObject();
          canal.id = element.id;
          canal.value = element.value;
          this.canales.push(canal);
        });
      });  
  }

  GetListBancos(){
    this.serviceMedioPago.getListBancos()
    .subscribe(res => {
      res.data.forEach(element => {
        let banco: Banco = new Banco();
        banco.codigo = element.codigo;
        banco.nombre = element.nombre;
        banco.bloqueadoEnCanales = element.bloqueadoEnCanales;
        this.bancos.push(banco);
      });
    });
  }


  SeleccionaBancoGestion(canalesBloqueados): void {
    this.canalesBancoSeleccionado = [];
    this.cambiosHechosList = [];

    this.canales.forEach(element => {
      let canalBanco: CanalObject = new CanalObject();
      canalBanco.nombre = element.value;
      var canalBloqueado = canalesBloqueados.includes(element.value);     
      canalBanco.estado = !canalBloqueado;
      this.canalesBancoSeleccionado.push(canalBanco);
    });
  }

  AgregarCambioBanco(event){

    var estado = event.target.checked;

    var canalIndex = this.canalesBancoSeleccionado.findIndex(canal => canal.nombre == event.target.nombre);
    this.canalesBancoSeleccionado[canalIndex].estado = estado;
  } 

  UpdateCanalesBanco(): void {

    this.requestUpdateBanco.codigo = this.bancoSeleccionado;   
    this.cambiosHechosList = this.canalesBancoSeleccionado.filter(function(canal) {
      return canal.estado === false;
    }).map(function(canal) {
      return canal.nombre;
    });
    this.requestUpdateBanco.bloqueadoEnCanales = this.cambiosHechosList;
    
    this.serviceMedioPago.updateListCanalesBanco(this.requestUpdateBanco)
      .subscribe(res => {
          this.bancos = [];
          this.GetListBancos();
          Swal.fire(
            'Correcto',
            'Canales actualizados exitosamente!',
            'success'
          )  
      }, err => {
      });
  }

}

export class CanalObject {
  nombre: string;
  estado: boolean;
}

export class MedioPagoObject {
  id: number;
  value: string;
}
