import { Pnr } from './requestserviceemision';
export class ResponseServiceEmision {
    Success: Success[];
    Failed: Failed[];
    validationError : boolean;
}

export class Success {
    pnr: string;
    lastName: string;
    FP: number;
}

export class Failed {
    pnr: string;
    lastName: string;
    FP: number;
    error: string;
    field: string;
    value: string;
    message: string;
}

export class PnrStateful {
    Pnr: string;
    lasName: string;
    status: string;

}
