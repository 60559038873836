<app-navbar></app-navbar>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="container" style="margin-left: 10%; margin-right: 10%;">

    <form class="form-inline my-5 " style="width: 1500px;">
        <div class=" card border-stylish-color-dark  my-5" style="width: 1500px;">
            <div class="card-header text-light  "></div>
            <div class="card-body text-app-color">
                <h5 class="card-title">Filtros de busqueda</h5>
            </div>
            <div class="form-group">
                <div class="container">
                    <div class="row align-items-center" style="width: 1170px;">
                        <div class="input-group text-light col-xs-auto col-sm-auto col-md-2" >
                            <label for="lastname" class="col-form-label">rt/OfficeID-Apellido*A</label>
                        </div>
                        <div class="input-group text-app-color col-xs-auto col-sm-auto col-md-2">
                            <div class="custom-control custom-radio">
                                <input type="radio" class="custom-control-input" id="rdFiltro1" name="defaultExampleRadios" (click)="limpiaOpcion($event)" mdbInput>
                                <label class="custom-control-label" for="rdFiltro1">Selección 1</label>
                            </div>
                        </div>
                        <div class="input-group text-app-color col-xs-auto col-sm-auto col-md-3">
                            <label for="lastname" class="col-form-label">Apellido</label>
                            <div class="input-group-append col-xs-auto col-sm-auto col-md-8">
                                <input type="text" class="form-control col-xs-auto col-sm-auto col-md-auto" [disabled]="(opcion1 == false)" name="txtlastname1" [(ngModel)]="name1" >
                            </div>
                        </div>
                        <div class="input-group text-app-color col-xs-auto col-sm-auto col-md-4">
                            <label for="oid" class="col-form-label">OfficeID</label>
                            <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                                <input type="text" class="form-control col-xs-auto col-sm-auto col-md-8" [disabled]="(opcion1 == false)" name="txtoid" [(ngModel)]="oid" maxlength="10">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <!--<div class="row align-items-left">
                        <div class="input-group text-app-color col-xs-auto col-sm-auto col-md-2">
                            <div class="custom-control custom-radio">
                                <input type="radio" class="custom-control-input" id="rdFiltro2" name="defaultExampleRadios" (click)="limpiaOpcion($event)" mdbInput>
                                <label class="custom-control-label" for="rdFiltro2">Selección 2</label>
                            </div>
                        </div>
                        <div class="input-group text-app-color col-xs-auto col-sm-auto col-md-4">
                            <label for="lastname2" class="col-form-label">Apellido</label>
                            <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                                <input type="text" class="form-control col-xs-auto col-sm-auto col-md-auto" [disabled]="(opcion2 == false)" name="txtlastname2" [(ngModel)]="name2" >
                            </div>
                        </div>
                        <div class="input-group text-app-color col-xs-auto col-sm-auto col-md-6">
                            <label for="dp" class="col-form-label">Fecha</label>
                            <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                                <input [owlDateTime]="dt1" placeholder="MM/dd/yyyy"  class="form-control col-xs-auto col-sm-auto col-md-7" [disabled]="(opcion2 == false)" name="dp" [(ngModel)]="fecha1">
                                <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time> 
                                <button class="btn btn-outline-secondary calendar" [owlDateTimeTrigger]="dt1" [disabled]="(opcion2 == false)"  type="button"><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar-date-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-6.664-1.21c-1.11 0-1.656-.767-1.703-1.407h.683c.043.37.387.82 1.051.82.844 0 1.301-.848 1.305-2.164h-.027c-.153.414-.637.79-1.383.79-.852 0-1.676-.61-1.676-1.77 0-1.137.871-1.809 1.797-1.809 1.172 0 1.953.734 1.953 2.668 0 1.805-.742 2.871-2 2.871zm.066-2.544c.625 0 1.184-.484 1.184-1.18 0-.832-.527-1.23-1.16-1.23-.586 0-1.168.387-1.168 1.21 0 .817.543 1.2 1.144 1.2zm-2.957-2.89v5.332H5.77v-4.61h-.012c-.29.156-.883.52-1.258.777V8.16a12.6 12.6 0 0 1 1.313-.805h.632z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr>-->
                    <div class="row align-items-center" style="width: 1170px;">
                        <div class="input-group text-light col-xs-auto col-sm-auto col-md-2">
                            <label for="lastname" class="col-form-label">rtAV/Vuelo/Fecha-Apellido</label>
                        </div>
                        <div class="input-group text-app-color col-md-2">
                            <div class="custom-control custom-radio">
                                <input type="radio" class="custom-control-input" id="rdFiltro3" name="defaultExampleRadios" (click)="limpiaOpcion($event)" mdbInput>
                                <label class="custom-control-label" for="rdFiltro3">Selección 2</label>
                            </div>
                        </div>
                        <div class="input-group text-app-color col-md-3">
                            <label for="lastname3" class="col-form-label">Apellido</label>
                            <div class="input-group-append col-xs-auto col-sm-auto col-md-8">
                                <input type="text" class="form-control col-xs-auto col-sm-auto col-md-auto" [disabled]="(opcion3 == false)" name="txtlastname3" [(ngModel)]="name3">
                            </div>
                        </div>
                        <div class="input-group text-app-color col-md-3">
                            <label for="df" class="col-form-label">Fecha</label>
                            <div class="input-group-append col-xs-auto col-sm-auto col-md-7">
                                <input [owlDateTime]="dt2" placeholder="MM/dd/yyyy"  class="form-control col-xs-auto col-sm-auto col-md-11" [disabled]="(opcion3 == false)" name="df" [(ngModel)]="fecha2">
                                <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time> 
                                <button class="btn btn-outline-secondary calendar" [owlDateTimeTrigger]="dt2" [disabled]="(opcion3 == false)"  type="button"><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar-date-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-6.664-1.21c-1.11 0-1.656-.767-1.703-1.407h.683c.043.37.387.82 1.051.82.844 0 1.301-.848 1.305-2.164h-.027c-.153.414-.637.79-1.383.79-.852 0-1.676-.61-1.676-1.77 0-1.137.871-1.809 1.797-1.809 1.172 0 1.953.734 1.953 2.668 0 1.805-.742 2.871-2 2.871zm.066-2.544c.625 0 1.184-.484 1.184-1.18 0-.832-.527-1.23-1.16-1.23-.586 0-1.168.387-1.168 1.21 0 .817.543 1.2 1.144 1.2zm-2.957-2.89v5.332H5.77v-4.61h-.012c-.29.156-.883.52-1.258.777V8.16a12.6 12.6 0 0 1 1.313-.805h.632z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="input-group text-app-color col-md-2">
                            <label for="vuelo" class="col-form-label">Vuelo</label>
                            <div class="input-group-append col-xs-auto col-sm-auto col-md-7">
                                <input type="text" class="form-control col-xs-auto col-sm-auto col-md-auto" [disabled]="(opcion3 == false)" name="txtnumvuelo" [(ngModel)]="vuelo">
                            </div>
                        </div>
                    </div>
                    <hr>
                    
                    
                </div>
                
            </div>
            <div class="row my-5">
                <div class="col-12 text-center">
                    <button class="btn btn-danger" role="button" [disabled]="(opcion1 == false && opcion2 == false && opcion3 == false)" (click)="findDuplex();">Buscar</button>
                </div>
            </div>
        </div>
        
    </form>

    
    <div class=" card border-stylish-color-dark  my-5" [hidden]="listpnrs?.length==0 || listpnrEliminados?.length>0" style="width: 1500px;">
        <div class="card-header text-light  "></div>
        <div class="card-body text-app-color">
            <h5 class="card-title">Listado de pnr´s</h5>
            <div class="alert alert-info" *ngIf="listpnrs?.length==0">
                No se encontraron resgistros!
            </div>
            <div class="col-auto">
                <table mdbTable #tableEl="mdbTable" class="table table-bordered table-striped table-hover table-sm table-responsive-lg ">
                    <thead>
                        <tr>
                            <th class="text-center">Registro</th>
                            <th class="text-center">Nombre</th>
                            <th class="text-center">Aerolinea</th>
                            <th class="text-center">Vuelo</th>
                            <th class="text-center">Clase</th>
                            <th class="text-center">Fecha</th>
                            <th class="text-center">Ruta</th>
                            <th class="text-center">Cantidad</th>
                            <th class="text-center">Reserva</th>
                            <th class="text-center">Eliminar</th>
                            <th class="text-center">Notas</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pnr of listpnrs; let i = index">
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">{{pnr.registro}}</td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">{{pnr.nombre}}</td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">{{pnr.aerolinea}}</td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">{{pnr.vuelo}}</td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">{{pnr.clase}}</td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">{{pnr.fecha}}</td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">{{pnr.ruta}}</td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">{{pnr.cantidad}}</td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">{{pnr.reserva}}</td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row">
                                <div class="input-group-prepend justify-content-center">
                                    <div class="input-group-text center checkbox-inline">
                                        <input type="checkbox" id="fp_{{i+1}}"  aria-label="Checkbox for following text input" [(ngModel)]="pnr.eliminar" (change)="checkIfaItemIsSelected();">
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row">
                                <div class="input-group-prepend justify-content-center">
                                    <div class="input-group-text center">
                                        <input type="text" id="selected_{{i+1}}"  aria-label="textbox for following text input" [(ngModel)]="pnr.notas"><!--[disabled]="emitDisable(pnr)" [(ngModel)]="pnr.selected" (change)="checkIfAllSelected();checkIfaItemIsSelected();disableFPCheck($event,pnr)"-->
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="grey lighten-5 w-100">
                        <tr>
                            <td colspan="11">
                                <mdb-table-pagination #mdbTablePagination [tableEl]="tableEl" [searchDataSource]="listpnrs"></mdb-table-pagination>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                <div class="float-right my-3">
                    <button class="btn btn-danger" role="button" (click)="sendEliminacion()" [disabled]="checkIfCanNotEmit()">Ejecutar</button>
                </div>
            </div>
        </div>
    </div>

    <div class=" card border-stylish-color-dark  my-5 " [hidden]="listpnrEliminados?.length==0" style="width: 1500px;">
        <div class="card-header text-light  "></div>
        <div class="card-body text-app-color ">
            <h5 class="card-title">Resultado de la Eliminación</h5>
            <div class="alert alert-info" *ngIf="listpnrs?.length==0">
                Sin resultados de eliminación!
            </div>
            <div class="col-auto">

                <table mdbTable #tableE2="mdbTable" class="table table-bordered table-striped table-hover table-sm table-responsive-lg ">
                    <thead>
                        <tr>
                            <th class="text-center">PNR</th>
                            <th class="text-center">Notas</th>
                            <th class="text-center">Estado</th>
                            <th class="text-center">Reintento</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pnr of listpnrEliminados; let i = index">
                            <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex" scope="row" class="text-center">{{pnr.Pnr}}</td>
                            <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex" scope="row" class="text-center">{{pnr.comment}}</td>
                            <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex" scope="row" class="text-center">{{pnr.status}}</td>
                            <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex" scope="row">
                                <div class="input-group-prepend justify-content-center">
                                    <div class="input-group-text center checkbox-inline">
                                        <input type="checkbox" id="fr_{{i+1}}"  aria-label="Checkbox for following text input" [(ngModel)]="pnr.reintento" [disabled]="pnr.status.includes('Precaución: ') == false" (change)="checkIfaItemIsSelected2();"> <!--(change)="checkIfaItemIsSelected();"-->
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="grey lighten-5 w-100">
                        <tr>
                            <td colspan="4">
                                <mdb-table-pagination #mdbTablePaginationResult [tableEl]="tableE2" [searchDataSource]="listpnrEliminados"></mdb-table-pagination>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <div class="float-right my-3">
                    <button class="btn btn-danger" role="button" [hidden]="checkIfCanRetry()" [disabled]="checkIfCanNotRetry()" (click)="retryEliminacion()">Reintentar</button>
                </div>
            </div>
        </div>
    </div>
</div>      