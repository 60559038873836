import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import * as Papa from 'papaparse';
import { RelanzamientosService } from 'src/app/services/relanzamientos.service';
import { RequestReservasPurgadas } from 'src/app/shared/request-reservas-purgadas';
import { environment } from 'src/environments/environment';
import { AnonymousCredential, BlobServiceClient, newPipeline, BlobUploadCommonResponse } from '@azure/storage-blob';


@Component({
  selector: 'app-purgadas',
  templateUrl: './purgadas.component.html',
  styleUrls: ['./purgadas.component.css']
})
export class PurgadasComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) tableE1: MdbTableDirective;
  @ViewChild('form') form;
  fechaEmision: Date;
  fechaOut: Date;
  fechaIn: Date;
  today = new Date();
  startAt = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 0, 0);
  listaReservas: { PNR: string }[] = [];
  file: File;

  constructor(private relanzamientosService: RelanzamientosService,
    private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService) {
  }

  ngAfterViewInit(): void {
    this.reset();
  }

  ngOnInit(): void {
  }

  reset(): void {
    this.fechaEmision = undefined;
    this.fechaEmision = undefined;
    this.fechaEmision = undefined;
    this.listaReservas = [];
    this.file = undefined;
  }

  validaFile(files: File[]): boolean {
    if (files[0].name.includes('.csv') ||
      files[0].name.includes('.txt') ||
      files[0].name.includes('.TXT') ||
      files[0].name.includes('.CSV')) {
      return true;
    } else {
      return false;
    }
  }

  validaCabeceras(file: any[]): boolean {

    return file.includes('RecordLocator(old)') ? true : false;
  }

  validaSize(file: File): boolean {
    return file.size <= 50000 ? true : false;
  }

  onChange(files: File[]): void {
    this.spinnerService.show();
    if (this.validaFile(files) && files[0]) {
      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        complete: (result, file) => {
          if (this.validaCabeceras(result.meta.fields)) {
            if(result.data.length <=70) {
              if (this.validaSize(file)) {
                console.log(result.data);
                this.listaReservas = result.data;
                this.file = file;
                console.log(this.listaReservas);
                //Se cambia el nombre de la cabecera RecordLocator(old) del archivo csv
                let listaReservasModificada = JSON.parse(JSON.stringify(this.listaReservas).split('"RecordLocator(old)":').join('"PNR":'));
                console.log(listaReservasModificada);
                this.listaReservas = listaReservasModificada;
                
                this.configureTable();
              } else {
                Swal.fire('El archivo supera el limite de tamaño de 50 K', '', 'info');
              }
            } else {
              this.reset();
              Swal.fire('El limite de registros a procesar es 70, y el archivo tiene ' + result.data.length + ' registros, por favor validar', '', 'info');
            }
          } else {
            Swal.fire('El archivo no cuenta con las cabeceras correspondientes, por favor validar', '', 'info');
          }
          this.spinnerService.hide();
        }
      });
    } else {
      Swal.fire('El archivo no es de tipo txt o un csv, intente con otro archivo', '', 'info');
      this.spinnerService.hide();
    }
  }

  configureTable(): void {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);
    this.tableE1.setDataSource(this.listaReservas);
    this.listaReservas = this.tableE1.getDataSource();
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.mdbTablePagination.firstPage();
    this.cdRef.detectChanges();
  }

  sendFile(): void {
    if (!this.fechaOut) {
      Swal.fire('La fecha de ida es obligatoria, por favor ingresarla', '', 'info');
    } else if (!this.fechaEmision) {
      Swal.fire('La fecha de emisión es obligatoria, por favor ingresarla', '', 'info');
    } else if (this.listaReservas.length === 0 || !this.file) {
      Swal.fire('Es necesario cargar un archivo con reservas', '', 'info');
    } else {
      this.spinnerService.show();
      const request = new RequestReservasPurgadas();
      request.FechaRegreso = this.fechaIn.toISOString();
      request.FechaIda = this.fechaOut.toISOString();
      request.FechaEmision = this.fechaEmision.toISOString();
      request.NombreArchivo = this.file.name;
      this.uploadStorage().then(response => {
        console.log('response uploadStorage '+ response._response.status, response);
        this.relanzamientosService.sendReservasPurgadas(request).subscribe(response => {
          console.log('response sendReservasPurgadas '+ JSON.stringify(response));
          Swal.fire('Reservas procesadas, por favor consulte el reporte de resultados.', '', 'success');
          this.spinnerService.hide();
        }, err => {
          console.log(err);
          this.spinnerService.hide();
        });
        Swal.fire('Archivo cargado exitosamente, haga clic en OK para procesar las reservas. Este proceso puede tardar varios minutos.', '', 'success');
        this.spinnerService.hide();
        this.reset();
      }).catch(err => {
        console.log(err);
        //Swal.fire('Ha ocurrido un error subiendo el archivo', '', 'error');
        this.spinnerService.hide();
      });
    }
  }

  uploadStorage(): Promise<BlobUploadCommonResponse> {
    const pipeline = newPipeline(new AnonymousCredential(), {
      retryOptions: { maxTries: 3 },
      userAgentOptions: { userAgentPrefix: 'Relaunch purgadas V1.0.0' },
      keepAliveOptions: {
        enable: false
      }
    });

    const blobServiceClient = new BlobServiceClient(environment.storageToken,pipeline);
    const containerClient = blobServiceClient.getContainerClient(environment.storageContainer);
    const client = containerClient.getBlockBlobClient(this.file.name);
    return client.uploadData(this.file, {
      blockSize: this.file.size,
      concurrency: 20,
      blobHTTPHeaders: { blobContentType: this.file.type }
    });
  }
}
