import { Inject, Injectable } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventType, InteractionRequiredAuthError, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { Subject, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();
  private accessToken: any;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService) { }

  updateLoggedInStatus() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoggedIn();
        this.checkAndSetActiveAccount();
      });
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.loginWithPopup();
    } else {
      this.loginWithRedirect();
    }
  }

  getActiveAccount(): AccountInfo | null {
    //window.alert(this.authService.instance.getActiveAccount().idTokenClaims["groups"])
    //console.log(JSON.stringify(this.authService.instance.getActiveAccount()));
    return this.authService.instance.getActiveAccount();
  }

  private checkAndSetActiveAccount() {
    /**
    * If no active account set but there are accounts signed in, sets first account to active account
    * To use active account set here, subscribe to inProgress$ first in your component
    * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
    */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  private setLoggedIn() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  private loginWithPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  private loginWithRedirect() {

    const loginRequest = {
      scopes: ["openid", "profile", "User.Read"]
    };

    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect(loginRequest as RedirectRequest);

      this.authService.instance.addEventCallback((event) => {
        // set active account after redirect
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const account = event.payload.account;
          this.authService.instance.setActiveAccount(account);
        }
      });

      this.authService.instance.handleRedirectPromise().then(authResult => {
        // Check if user signed in 
        const account = this.authService.instance.getActiveAccount();
        if (!account) {
          // redirect anonymous user to login page 
          this.authService.instance.loginRedirect();
        }

        this.accessToken = authResult.idToken;
        if (this.accessToken) {
          localStorage.setItem("idtoken", this.accessToken);
        }

      }).catch(err => {
        // TODO: Handle errors
        console.log(err);
      });

      this.updateLoggedInStatus();

    } else {
      this.authService.loginRedirect(loginRequest as RedirectRequest);
     
      this.authService.instance.addEventCallback((event) => {
        // set active account after redirect
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const account = event.payload.account;
          this.authService.instance.setActiveAccount(account);
        }
      });

      this.authService.instance.handleRedirectPromise().then(authResult => {
        // Check if user signed in 
        const account = this.authService.instance.getActiveAccount();
        if (!account) {
          // redirect anonymous user to login page 
          this.authService.instance.loginRedirect();
        }

        this.accessToken = authResult.idToken;

        if (this.accessToken) {
          localStorage.setItem("idtoken", this.accessToken);
        }
        
      }).catch(err => {
        // TODO: Handle errors
        console.log(err);
      });

      this.updateLoggedInStatus();
    }
  }

  logout() {
    this.authService.logout();
  }

  destroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  public async GetAccessToken(): Promise<Observable<any>> {
    if (localStorage.getItem('idtoken') !== undefined && localStorage.getItem('idtoken') != null) {
      this.accessToken = localStorage.getItem('idtoken');

      if (this.tokenExpired(this.accessToken) == false) {
        return this.accessToken;
      }
      else {

        //alert("token expired");

        const accessTokenRequest: SilentRequest = {
          scopes: ['user.read', 'profile'],
          account: this.getActiveAccount(),
          forceRefresh: true
        }

        const request: RedirectRequest = {
          scopes: ['user.read', 'profile'],
          loginHint: this.getActiveAccount().username,
        }

        this.accessToken = await this.authService.instance.acquireTokenSilent(accessTokenRequest).then(result => {
          //alert(result.idToken);
          this.accessToken = result.idToken;
          localStorage.setItem("idtoken", result.idToken);

          /*if (this.tokenExpired(this.accessToken) == false) {*/
          return result.idToken;
          /*}*/
        }).catch(error => {
          if (error instanceof InteractionRequiredAuthError) {
            return this.authService.instance.acquireTokenRedirect(request);
          }
        })

      }

    }
    else {
      this.authService.instance.logoutRedirect();
      this.authService.instance.loginRedirect();
    }

    
  }

  protected interactionRequired = (): Promise<string> => {
    console.log("Inside Interaction");
    const loginPopupRequest: PopupRequest = {
      scopes: ['user.read', 'profile'],
      account: this.getActiveAccount()
    }
    loginPopupRequest.loginHint = this.getActiveAccount().username;
    return this.authService.instance
      .acquireTokenPopup(loginPopupRequest)
      .then((tokenResponse) => {
        return tokenResponse.idToken;
      })
      .catch((error) => {
        console.error(error);
        // I haven't implemented redirect but it is fairly easy
        console.error("Maybe it is a popup blocked error. Implement Redirect");
        return null;
      });
  };
}
