import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map, timeout } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { RequestSearchServiceWorldpay } from '../shared/request-search-service-worldpay';
import { RequestServiceVoidWorldpay } from '../shared/request-service-void-worldpay';
import { ResponseServiceVoidWorldpay } from '../shared/response-service-void-worldpay';

@Injectable({
  providedIn: 'root'
})
export class WorldpayService {

  private httpHeaders;
  private suscription = environment.ocpSuscription;
  private authorization = environment.authorization;
  private endpointTransactionsPending = environment.urlServiceConsultaWP;
  private endpointTransactionsVoid = environment.urlServiceVoidWP;

  constructor(private http: HttpClient) { }

  getTransactionsPendingCapture(request: RequestSearchServiceWorldpay): Observable<any> {
    this.httpHeaders =
      new HttpHeaders
        ({
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': this.suscription,
          //'metodo': 'consulta'
        });

    return this.http.post(this.endpointTransactionsPending, request, { headers: this.httpHeaders })
      .pipe(
        map(response => response as any),
        timeout(30000),
        catchError(e => {
          Swal.fire('Error de comunicación', e.message, 'error');
          return throwError(e);
        })
      );
  }

  sendVoid(request: RequestServiceVoidWorldpay): Observable<ResponseServiceVoidWorldpay> {
    this.httpHeaders =
      new HttpHeaders
        ({
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': this.suscription,
          'Authorization': this.authorization,
          //'metodo': 'void'
        });

    return this.http.post(this.endpointTransactionsVoid, request, { headers: this.httpHeaders })
      .pipe(
        map(response => response as ResponseServiceVoidWorldpay),
        timeout(30000),
        catchError(e => {
          Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
          return throwError(e);
        })
      );
  }

}
