import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  title = 'app-relanzamientos-emision';

  constructor(public authService: AuthService) {}

  ngOnInit(): void {}

  login() {
    this.authService.login();    
    this.authService.updateLoggedInStatus();
  }

}
