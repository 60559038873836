import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
 
@Injectable()

export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthService
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request and set the new header in one step.
    const currentUser = this.authenticationService.getActiveAccount();
    if (currentUser) {
      const authReq = req.clone({headers: req.headers.set('user', currentUser.username)});
 
      // send the newly created request
      return next.handle(authReq);
    } else if (document.location.pathname.includes("modulo-externo")) {
      // send the newly created request
      return next.handle(req);
    }
  }
}