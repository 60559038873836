<nav class="navbar navbar-expand-lg navbar-dark danger-color-dark">
    <a class="navbar-brand" href="#">Relanzamientos</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="!pathExterno">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item dropdown" *ngIf="authGuard.visualizaMenu('administrarModulos')">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Administración de módulos
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" routerLink="/gestionModulos" *ngIf="authGuard.visualizaMenu('gestionModulos')">Gestión de módulos</a>
                    <a class="dropdown-item" routerLink="/historialLogs" *ngIf="authGuard.visualizaMenu('historialLogs')">Historial de logs</a>
                </div>
            </li>
            <li class="nav-item dropdown" *ngIf="authGuard.visualizaMenu('monitoreo')">
               <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Monitoreo
               </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" routerLink="/relanzamientos" *ngIf="authGuard.visualizaMenu('relanzamientos')">Emisiones ViancaPay</a>
                    <a class="dropdown-item" routerLink="/tarjetaGenerica" *ngIf="authGuard.visualizaMenu('tarjetaGenerica')">Emisiones Tarjetas genericas</a>
                    <a class="dropdown-item" routerLink="/duplex" *ngIf="authGuard.visualizaMenu('duplex')">Eliminación Manual Reservas Duplex</a>
                    <a class="dropdown-item" routerLink="/EmisionesFPGenerica" *ngIf="authGuard.visualizaMenu('EmisionesFPGenerica')">Emisiones FP Generica</a>
                    <a class="dropdown-item" routerLink="/adicionarTKXL" *ngIf="authGuard.visualizaMenu('adicionarTKXL')">Adicionar TKXL</a>
                </div>
            </li>
            <li class="nav-item dropdown" *ngIf="authGuard.visualizaMenu('reservasPurgadas')">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                 Reservas purgadas
                </a>
                 <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                     <a class="dropdown-item" routerLink="/purgadas" *ngIf="authGuard.visualizaMenu('purgadas')">Carga Reservas purgadas</a>
                     <a class="dropdown-item" routerLink="/archivoCargado" *ngIf="authGuard.visualizaMenu('archivoCargado')">Consulta Archivo Cargado</a>
                 </div>
             </li>
             <li class="nav-item dropdown" *ngIf="authGuard.visualizaMenu('cancelaciones')">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Void auth
                </a>
                 <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                     <a class="dropdown-item" routerLink="/CancelacionesUatp" *ngIf="authGuard.visualizaMenu('CancelacionesUatp')">Cancelar autorizaciones de UATP</a>
                     <a class="dropdown-item" routerLink="/AutorizacionesWorldpay" *ngIf="authGuard.visualizaMenu('AutorizacionesWorldpay')">Cancelar autorizaciones de Worldpay</a>
                 </div>
             </li>
             <li class="nav-item dropdown" *ngIf="authGuard.visualizaMenu('gestion')">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Gestión
                </a>
                 <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                     <a class="dropdown-item" routerLink="/medios-pago-gestion" *ngIf="authGuard.visualizaMenu('medios-pago-gestion')">Act/Desact Medios de Pago</a>
                     <a class="dropdown-item" routerLink="/bancos-pse-gestion" *ngIf="authGuard.visualizaMenu('bancos-pse-gestion')">Act/Desact Bancos PSE</a>
                 </div>
             </li>
             <li class="nav-item dropdown" *ngIf="authGuard.visualizaMenu('reembolsos')">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Reembolsos
                </a>
                 <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                     <a class="dropdown-item" routerLink="/reembolsosWorldpay" *ngIf="authGuard.visualizaMenu('reembolsosWorldpay')">Reembolsos Worldpay</a>
                     
                 </div>
             </li>
        </ul>
        <button class="btn btn-outline-light my-2 my-sm-0" (click)="logoff()">Salir</button>
    </div>
</nav>