import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../../auth.service';
import { Permisos } from '../../shared/permisos';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) { }


  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    var visualiza: boolean = this.visualizaMenu(route.url.toString());

    if (visualiza == false) {
      this.router.navigate(['/']);
    }

    return visualiza;

  }

  visualizaMenu(path: string): boolean {

    const currentUser = this.authenticationService.getActiveAccount();
    var permisosApp = <Permisos[]>JSON.parse(JSON.stringify(environment.permisosApp));

    if (currentUser) {

      if (permisosApp.filter(item => item.path === path).length == 0) {
        return false;
      }

      var dato:boolean = false,
          grupos = permisosApp.filter(item => item.path === path)[0].groups,
          gruposUser:any = currentUser.idTokenClaims["groups"];

      grupos.forEach(e => {
        gruposUser.forEach(i => {
          if (i.includes(e)){
            dato = true;
          }
        });
      });

      if (grupos && !dato) {
        return false;
      }
      
    }

    return true;
  }
  
}
