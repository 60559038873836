<body class="bg-light">
    <div class="container">
        <div class="row">

            <div class="col-sm-9 col-md-7 col-lg-5 mx-auto my-5">
                <div class="card form-holder">
                    <div class="card-body  center-block my-5">
                        <h2 class="text-center titlle-login">App-Relanzamientos</h2>
                        <div class="row my-5">
                            <div class="col-12 text-center">
                                <button class="btn btn-danger btn-block" (click)="login()">Ingresar Con Microsoft</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</body>