import { Pnr } from './requestserviceFPemision';
export class ResponseServiceFPEmision {
    Success: Success[];
    Failed: Failed[];
    validationError : boolean;
}

export class Success {
    pnr: string;
    lastName: string;
    franquicia: string;
    oid : string;
    paymentMethod: string;
    cus: string;
}

export class Failed {
    pnr: string;
    lastName: string;
    franquicia: string;
    oid : string;
    paymentMethod: string;
    cus: string;
    error: string;
    field: string;
    value: string;
    message: string;
}

export class PnrStateful {
    pnr: string;
    lastName: string;
    franquicia: string;
    oid : string;
    paymentMethod: string;
    cus: string;
    status:  string;
}
