export class RequestHistorialLogs {
    user: string;
    startDate: string;
    endDate: string;
}

export class ResponseHistorialLogs {
    response: FieldsLogs[]
}

export class FieldsLogs {
    idSeguimiento: string;
    usuario: string;
    operacion: string;
    fechaActualizacion: string;
}
