<app-navbar></app-navbar>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<section class="bg-light" *ngIf="!logginUser">
    <div class="container">
        <div class="row">
            <div class="col-sm-9 col-md-7 col-lg-5 mx-auto my-5">
                <div class="card form-holder">
                    <div class="card-body  center-block my-5">
                        <h2 class="text-center titlle-login">Módulo Vision</h2>
                        <div class="row my-5">
                            <div class="col-12 text-center">
                                <form class="form-inline">
                                    <div class="form-group col-xs-auto col-sm-auto col-md-12">
                                        <div class="container">
                                            <div class="input-group text-app-color">
                                                <div class="col-xs-auto col-sm-auto col-md-12">
                                                    <label for="canal" class="col-form-label">USUARIO</label>
                                                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                                                    <input type="text" class="form-control col-xs-12 col-sm-12 col-md-12" name="userVision"
                                                    [(ngModel)]="userVision" #userfilter="ngModel">
                                                    </div>
                                                </div>
                                                <div class="col-xs-auto col-sm-auto col-md-12">
                                                    <label for="canal" class="col-form-label">CONTRASEÑA</label>
                                                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                                                    <input type="password" class="form-control col-xs-12 col-sm-12 col-md-12" name="pwdVision"
                                                    [(ngModel)]="pwdVision" #userfilter="ngModel">
                                                    </div>
                                                </div>
                                                <div class="col-xs-auto col-sm-auto col-md-12">
                                                    <label for="" class="col-form-label">&nbsp;</label>
                                                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                                                        <button class="btn btn-danger" role="button" (click)='loginVision()'
                                                        [disabled]="loginDisabled()">
                                                            Iniciar sesión
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                        
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section *ngIf="logginUser">
    <div class="container">
                    
        <div class="my-3 text-app-color">
            <h5 class="card-title">MÓDULO VISION</h5>
        </div>

        <hr/>

        <form class="form-inline my-5">
            <div class="form-group">
                <div class="container">
                    <div class="input-group text-app-color">
                        <div class="col-xs-auto col-sm-auto col-md-6">
                            <label for="canal" class="col-form-label">PNR</label>
                            <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                            <input type="text" class="form-control col-xs-12 col-sm-12 col-md-12" name="order"
                            [(ngModel)]="order" #userfilter="ngModel">
                            </div>
                        </div>
                        <div class="col-xs-auto col-sm-auto col-md-4">
                            <label for="pais" class="col-form-label">FECHA (opcional)</label>
                            <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                              <input [owlDateTime]="dt7" placeholder="mm/dd/yyyy" name="date"
                              [(ngModel)]="date" class="form-control" [max]="max">
                              <owl-date-time [pickerType]="'calendar'" [startAt]="startAt" #dt7></owl-date-time>
                              <button class="btn btn-outline-secondary calendar" [owlDateTimeTrigger]="dt7" type="button">
                                <svg width="1em" height="1em" viewBox="0 0 16 16"
                                class="bi bi-calendar-date-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd"
                                      d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-6.664-1.21c-1.11 0-1.656-.767-1.703-1.407h.683c.043.37.387.82 1.051.82.844 0 1.301-.848 1.305-2.164h-.027c-.153.414-.637.79-1.383.79-.852 0-1.676-.61-1.676-1.77 0-1.137.871-1.809 1.797-1.809 1.172 0 1.953.734 1.953 2.668 0 1.805-.742 2.871-2 2.871zm.066-2.544c.625 0 1.184-.484 1.184-1.18 0-.832-.527-1.23-1.16-1.23-.586 0-1.168.387-1.168 1.21 0 .817.543 1.2 1.144 1.2zm-2.957-2.89v5.332H5.77v-4.61h-.012c-.29.156-.883.52-1.258.777V8.16a12.6 12.6 0 0 1 1.313-.805h.632z" />
                                </svg>
                              </button>
                            </div>
                        </div>
                        <div class="col-xs-auto col-sm-auto col-md-6">
                            <label for="" class="col-form-label">&nbsp;</label>
                            <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                                <button class="btn btn-danger" role="button" (click)='searchStatus()'
                                [disabled]="buttonDisabled()">
                                    Consultar
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </form>

        <hr/>  

        <div class="card border-stylish-color-dark my-5" [hidden]="transactionsList?.length == 0">
            <div class="card-header text-light"></div>
            <div class="card-body text-app-color">
                <h5 class="card-title">Tabla de resultados</h5>
                <div class="col-auto">
                    <table mdbTable #tableEl="mdbTable"
                        class="table table-bordered table-striped table-hover table-sm table-responsive-lg">
                        <thead>
                            <tr>
                                <th class="text-center"><b>orderNo</b></th>
                                <th class="text-center"><b>txnId</b></th>
                                <th class="text-center"><b>txnTime</b></th>
                                <th class="text-center"><b>paymentStateTime</b></th>
                                <th class="text-center"><b>txnStatus</b></th>
                                <th class="text-center"><b>ticketed</b></th>
                                <th class="text-center"><b>approvalCode</b></th>
                                <th class="text-center"><b>paymentCurrency</b></th>
                                <th class="text-center"><b>paymentAmount</b></th>
                                <th class="text-center"><b>paymentMethod</b></th>
                                <th class="text-center"><b>preAuthFraudStatus</b></th>
                                <th class="text-center"><b>threeDSStatus</b></th>
                                <th class="text-center"><b>threeDSMessage</b></th>
                                <th class="text-center"><b>rejectionReason</b></th>
                                <th class="text-center"><b>splitPayment</b></th>
                                <th class="text-center"><b>pan</b></th>
                                <th class="text-center"><b>txnStateId</b></th>
                                <th class="text-center"><b>pricingAmount</b></th>
                                <th class="text-center"><b>cardHolderLastName</b></th>
                                <th class="text-center"><b>eci</b></th>
                                <th class="text-center"><b>txnType</b></th>
                                <th class="text-center"><b>preFraudPsp</b></th>
                                <th class="text-center"><b>merchantPaymentRef</b></th>
                                <th class="text-center"><b>routingRule</b></th>
                                <th class="text-center"><b>externalClaimRef</b></th>
                                <th class="text-center"><b>acquirerId</b></th>
                                <th class="text-center"><b>offeredExchangeRate</b></th>
                                <th class="text-center"><b>walletDetail</b></th>
                                <th class="text-center"><b>mpi</b></th>
                                <th class="text-center"><b>sessionStatus</b></th>
                                <th class="text-center"><b>ipAddress</b></th>
                                <th class="text-center"><b>postFraudPsp</b></th>
                                <th class="text-center"><b>storefront</b></th>
                                <th class="text-center"><b>amountDecimal</b></th>
                                <th class="text-center"><b>paymentRoute</b></th>
                                <th class="text-center"><b>pricingCurrency</b></th>
                                <th class="text-center"><b>rejectionType</b></th>
                                <th class="text-center"><b>fxService</b></th>
                                <th class="text-center"><b>cardIssuer</b></th>
                                <th class="text-center"><b>paymentType</b></th>
                                <th class="text-center"><b>purchasePath</b></th>
                                <th class="text-center"><b>currency</b></th>
                                <th class="text-center"><b>pricingDecimal</b></th>
                                <th class="text-center"><b>refundAmount</b></th>
                                <th class="text-center"><b>sessionAmount</b></th>
                                <th class="text-center"><b>instalment</b></th>
                                <th class="text-center"><b>clientId</b></th>
                                <th class="text-center"><b>capturedAmount</b></th>
                                <th class="text-center"><b>cardHolderName</b></th>
                                <th class="text-center"><b>cardType</b></th>
                                <th class="text-center"><b>parsesTxStatus</b></th>
                                <th class="text-center"><b>postAuthFraudStatus</b></th>
                                <th class="text-center"><b>authAmount</b></th>
                                <th class="text-center"><b>sessionId</b></th>
                                <th class="text-center"><b>cardHolderMobileNumber</b></th>
                                <th class="text-center"><b>cardHolderEmailId</b></th>
                                <th class="text-center"><b>issuerCountry</b></th>
                                <th class="text-center"><b>pointOfSale</b></th>
                                <th class="text-center"><b>versesEnrolledStatus</b></th>
                                <th class="text-center"><b>pspErrorDescription</b></th>
                                <th class="text-center"><b>externalPayReference</b></th>
                                <th class="text-center"><b>cardHolderFirstName</b></th>
                                <th class="text-center"><b>paymentDecimal</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of transactionsList">
                                <td class="text-center">{{item.orderNo}}</td>
                                <td class="text-center">{{item.txnId}}</td>
                                <td class="text-center">{{item.txnTime}}</td>
                                <td class="text-center">{{item.paymentStateTime}}</td>                                
                                <td class="text-center">{{item.txnStatus}}</td>
                                <td class="text-center">{{item.ticketed}}</td>
                                <td class="text-center">{{item.approvalCode}}</td>
                                <td class="text-center">{{item.paymentCurrency}}</td>
                                <td class="text-center">{{item.paymentAmount}}</td>
                                <td class="text-center">{{item.paymentMethod}}</td>
                                <td class="text-center">{{item.preAuthFraudStatus}}</td>
                                <td class="text-center">{{item.threeDSStatus}}</td>
                                <td class="text-center">{{item.threeDSMessage}}</td>
                                <td class="text-center">{{item.rejectionReason}}</td>
                                <td class="text-center">{{item.splitPayment}}</td>
                                <td class="text-center">{{item.pan}}</td>
                                <td class="text-center">{{item.txnStateId}}</td>
                                <td class="text-center">{{item.pricingAmount}}</td>
                                <td class="text-center">{{item.cardHolderLastName}}</td>
                                <td class="text-center">{{item.eci}}</td>
                                <td class="text-center">{{item.txnType}}</td>
                                <td class="text-center">{{item.preFraudPsp}}</td>
                                <td class="text-center">{{item.merchantPaymentRef}}</td>
                                <td class="text-center">{{item.routingRule}}</td>
                                <td class="text-center">{{item.externalClaimRef}}</td>
                                <td class="text-center">{{item.acquirerId}}</td>
                                <td class="text-center">{{item.offeredExchangeRate}}</td>
                                <td class="text-center">{{item.walletDetail}}</td>
                                <td class="text-center">{{item.mpi}}</td>
                                <td class="text-center">{{item.sessionStatus}}</td>
                                <td class="text-center">{{item.ipAddress}}</td>
                                <td class="text-center">{{item.postFraudPsp}}</td>
                                <td class="text-center">{{item.storefront}}</td>
                                <td class="text-center">{{item.amountDecimal}}</td>
                                <td class="text-center">{{item.paymentRoute}}</td>
                                <td class="text-center">{{item.pricingCurrency}}</td>
                                <td class="text-center">{{item.rejectionType}}</td>
                                <td class="text-center">{{item.fxService}}</td>
                                <td class="text-center">{{item.cardIssuer}}</td>
                                <td class="text-center">{{item.paymentType}}</td>
                                <td class="text-center">{{item.purchasePath}}</td>
                                <td class="text-center">{{item.currency}}</td>
                                <td class="text-center">{{item.pricingDecimal}}</td>
                                <td class="text-center">{{item.refundAmount}}</td>
                                <td class="text-center">{{item.sessionAmount}}</td>
                                <td class="text-center">{{item.instalment}}</td>
                                <td class="text-center">{{item.clientId}}</td>
                                <td class="text-center">{{item.capturedAmount}}</td>
                                <td class="text-center">{{item.cardHolderName}}</td>
                                <td class="text-center">{{item.cardType}}</td>
                                <td class="text-center">{{item.parsesTxStatus}}</td>
                                <td class="text-center">{{item.postAuthFraudStatus}}</td>
                                <td class="text-center">{{item.authAmount}}</td>
                                <td class="text-center">{{item.sessionId}}</td>
                                <td class="text-center">{{item.cardHolderMobileNumber}}</td>
                                <td class="text-center">{{item.cardHolderEmailId}}</td>
                                <td class="text-center">{{item.issuerCountry}}</td>
                                <td class="text-center">{{item.pointOfSale}}</td>
                                <td class="text-center">{{item.versesEnrolledStatus}}</td>
                                <td class="text-center">{{item.pspErrorDescription}}</td>
                                <td class="text-center">{{item.externalPayReference}}</td>
                                <td class="text-center">{{item.cardHolderFirstName}}</td>
                                <td class="text-center">{{item.paymentDecimal}}</td>

                            </tr>
                        </tbody>
                        <tfoot class="grey lighten-5 w-100">
                            <tr>
                                <td colspan="63">
                                    <mdb-table-pagination #mdbTablePaginationResult [tableEl]="tableEl"
                                        [searchDataSource]="transactionsList"></mdb-table-pagination>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            
        </div>    
    </div>
</section>