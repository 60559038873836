export const environment = {
  production: false,
  urlService: 'https://az-am-con-use-prd.azure-api.net/API_ViancaPay/V1/relaunch',
  urlServiceUatp: 'https://az-am-con-use-prd.azure-api.net/API_ViancaPay/V1/UatpConsulta/consulta',
  urlServiceVoid: 'https://az-am-con-use-prd.azure-api.net/API_ViancaPay/V1/uatp/void',
  urlServiceVoidWP: 'https://az-am-con-use-prd.azure-api.net/API_ViancaPay/V1/voidWP',
  urlServiceConsultaWP: 'https://az-am-con-use-prd.azure-api.net/API_ViancaPay/V1/wpConsulta',
  urlAuthorization: 'https://login.microsoftonline.com/397ed031-3935-40b0-9c69-14fd1164db8f/oauth2/token',
  ocpSuscription: '76cb529585ad488c8a76eb0e16b1b7f4',
  authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6InEtMjNmYWxldlpoaEQzaG05Q1Fia1A1TVF5VSIsImtpZCI6InEtMjNmYWxldlpoaEQzaG05Q1Fia1A1TVF5VSJ9.eyJhdWQiOiJhcGk6Ly9lNDk4YTBkNy1mZjdmLTQ2YzktYTJiZi1iYWI0YTRhODM3YTUiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9hMmFkZGQzZS04Mzk3LTQ1NzktYmEzMC03YTM4ODAzZmMzYmYvIiwiaWF0IjoxNzEyMTc4Mjk0LCJuYmYiOjE3MTIxNzgyOTQsImV4cCI6MTcxMjE4MjE5NCwiYWlvIjoiRTJOZ1lGaVZFVm14NEpKMDFOV2lsMEZ2RFRxaUFRPT0iLCJhcHBpZCI6ImU0OThhMGQ3LWZmN2YtNDZjOS1hMmJmLWJhYjRhNGE4MzdhNSIsImFwcGlkYWNyIjoiMSIsImlkcCI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0L2EyYWRkZDNlLTgzOTctNDU3OS1iYTMwLTdhMzg4MDNmYzNiZi8iLCJvaWQiOiJlZDFmMmY5Zi0yOTZiLTQ5YWYtODhlYS0wMGY3ZDZlNjkzYTciLCJyaCI6IjAuQVNVQVB0MnRvcGVEZVVXNk1IbzRnRF9EdjllZ21PUl9fOGxHb3ItNnRLU29ONlVsQUFBLiIsInN1YiI6ImVkMWYyZjlmLTI5NmItNDlhZi04OGVhLTAwZjdkNmU2OTNhNyIsInRpZCI6ImEyYWRkZDNlLTgzOTctNDU3OS1iYTMwLTdhMzg4MDNmYzNiZiIsInV0aSI6ImNIbEMtV1ZSRkVPR3hKcFhlTy01QUEiLCJ2ZXIiOiIxLjAifQ.SjJnMUTQfNANL4lSiVbxB_Ga1ZBtz9rDTw-1MbQyB3EK-VXhqjryUHBNPHpzc97t5IHtc9cUYK4OCmSEjHHL3Timpvs1nAw49K1M2Dn4B1aXiVDEiR82Bs9SfbtMScaZfsHs6X1-8qz_o_PZkTCcqKxq5g18XdRLUc_P04G0HNF9Mk0-iFCHjSCC4HIHUm4kPBB6DmQDilm_PlOBd5lOwzh7NqC1Xe8MMjOJJpPTx106H5qxTXeWfvJWCrB9r8xJ8BarkkgOUDPXEqYLrZVATbfe-ZxsKwvueZSmj_eyid05-JbOYojKrTawe-QkcPWEH6PylHFwlKDBLd7BaBs3lA',
  urlServiceReservasPurgadas: 'https://az-am-con-use-prd.azure-api.net/API_ViancaPay/V1/purge',
  ocpSuscriptionReservasPurgadas: '3ece5f04c8e94d6c835aaf2aa5c23bab',
  storageToken: 'https://azstacrelanchuseprd.blob.core.windows.net/?sv=2020-08-04&ss=b&srt=co&sp=rwdlactfx&se=2050-10-07T22:04:07Z&st=2021-10-06T12:04:07Z&spr=https&sig=cD1HNzZZy%2FT8stRWCsAWvFuYLm%2BIGYS6lxfvXHOpfYE%3D',
  storageContainer: 'reservas-purgadas',
  urlReportingService: 'https://azwsreportprd.avianca.loc/Reports/report/ReservasPurgadas/TrazaArchivoCargado',
  urlServiceRefundsWP: 'https://az-am-con-use-prd.azure-api.net/API_ViancaPay/V1/WorldpayRefunds',
  urlServiceGestionModulos: 'https://az-am-con-use-prd.azure-api.net/API_ViancaPay/V1/gestionModulos',
  urlServiceHistorialLogs: 'https://az-am-con-use-prd.azure-api.net/API_ViancaPay/V1/historialLogs',
  redirectUrl: window.location.origin,
  clientId: '57e0af4f-d394-4b3b-bd54-6bec0e153168',
  tenantId: 'a2addd3e-8397-4579-ba30-7a38803fc3bf',
  urlServiceVisionReport: 'https://az-am-con-use-prd.azure-api.net/API_ViancaPay/V1/visionReport',
  userExterno: 'visioncce',
  passwordExterno: 'admin2024*',
  cellpointUser: 'avianca',
  cellpointPassword: 'zf4Bc6$TyF',
  permisosApp: []
};