import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { RequestServiceMedioPago } from '../../shared/request-service-mediopago';
import { UpdatedMedioPago, RequestServiceUpdateMedioPago } from '../../shared/request-service-update-mediopago';
import { MedioPagoData, ResponseServiceUpdateMedioPago } from '../../shared/response-service-mediopago';
import { MedioPagoService } from '../../services/mediopago.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-medios-pago-gestion',
  templateUrl: './medios-pago-gestion.component.html',
  styleUrls: ['./medios-pago-gestion.component.css']
})
export class MediosPagoGestionComponent implements OnInit {

  public canalValue: number | null;
  public paisValue: number | null;
  public medioPagoValue: number | null;
  public franquiciaValue: number | null;

  public canales: MedioPagoObject[] = [];
  public paises: MedioPagoObject[] = [];
  public mediosPago: MedioPagoObject[] = [];
  public franquicias: MedioPagoObject[] = [];

  public cambiosHechosList: UpdatedMedioPago[] = [];
  private requestUpdateMedioPago: RequestServiceUpdateMedioPago;
  private requestMedioPago: RequestServiceMedioPago;

  public canalSelected: boolean = false;
  public consultandoData: boolean = false;
  public listMediosPago: MedioPagoData[] = [];

  constructor(
    private serviceMedioPago: MedioPagoService) {
    this.cambiosHechosList = [];
    this.requestMedioPago = new RequestServiceMedioPago();
    this.requestUpdateMedioPago = new RequestServiceUpdateMedioPago();
  }

  ngOnInit(): void {

    this.canalSelected = false;
    const optionNull = { id: null, value:' '};

    this.serviceMedioPago.getListCanalesMedioPago()
      .subscribe(res => {
        res.data.forEach(element => {
          let canal: MedioPagoObject = new MedioPagoObject();
          canal.id = element.id;
          canal.value = element.value;
          this.canales.push(canal);
        });
      });

     this.serviceMedioPago.getListPaisesMedioPago()
      .subscribe(res => {
        this.paises.push(optionNull);
        res.data.forEach(element => {
          let pais: MedioPagoObject = new MedioPagoObject();
          pais.id = element.id;
          pais.value = element.value;
          this.paises.push(pais);
        });
      });

      this.serviceMedioPago.getListMedioPagos()
      .subscribe(res => {
        this.mediosPago.push(optionNull);
        res.data.forEach(element => {
          let medioPago: MedioPagoObject = new MedioPagoObject();
          medioPago.id = element.id;
          medioPago.value = element.value;
          this.mediosPago.push(medioPago);
        });
      res
      });

      this.serviceMedioPago.getListFranquiciasMedioPago()
      .subscribe(res => {
        this.franquicias.push(optionNull);
        res.data.forEach(element => {
          let franquicia: MedioPagoObject = new MedioPagoObject();
          franquicia.id = element.id;
          franquicia.value = element.value;
          this.franquicias.push(franquicia);
        });
      res
      });
  }

  SeleccionaCanal() {
    this.canalSelected = true;  
  }

  AgregarCambioMedioPago(event){
  
    var object = this.cambiosHechosList.find(medioPago => medioPago.idRelacionalProcesoPago === +event.target.id);
    var estado = event.target.checked;

    if(!object)  
    {  
        let medioPago: UpdatedMedioPago = new UpdatedMedioPago();
        medioPago.idRelacionalProcesoPago = +event.target.id;
        medioPago.estado = +estado;
        this.cambiosHechosList.push(medioPago);
    }   
    else  
    {  
      var index = this.cambiosHechosList.findIndex(medioPago => medioPago.idRelacionalProcesoPago === +event.target.id);
      this.cambiosHechosList.splice(index, 1);
    }  
  } 


  SearchMedioPago(): void {
    this.consultandoData = true;
    this.cambiosHechosList = [];
    this.listMediosPago = [];

    this.requestMedioPago.idCanal = this.canalValue;
    this.requestMedioPago.idPais = this.paisValue;
    this.requestMedioPago.idFranquicia = this.franquiciaValue;
    this.requestMedioPago.idMedioDePago = this.medioPagoValue;

    this.serviceMedioPago.getParametriaMedioPago(this.requestMedioPago)
      .subscribe(res => {
        res.data.forEach(element => {
          let medioPago: MedioPagoData = new MedioPagoData();
          medioPago.idRelacionalProcesoPago = element.idRelacionalProcesoPago;
          medioPago.canal = element.canal;
          medioPago.pais = element.pais;
          medioPago.medioDePago = element.medioDePago;
          medioPago.franquicia = element.franquicia;
          medioPago.medioDePagoPresencial = element.medioDePagoPresencial;
          medioPago.estado = element.estado;
          this.listMediosPago.push(medioPago);
        });
        this.consultandoData = false;
      }, err => {
      }
      );     
  }

  UpdateMedioPago(): void {

    this.requestUpdateMedioPago.request = this.cambiosHechosList;   

    this.serviceMedioPago.updateParametriaMedioPago(this.requestUpdateMedioPago)
      .subscribe(res => {
        if(res.result == 200)
        {
          this.listMediosPago = [];
          this.cambiosHechosList = [];
          this.SearchMedioPago();         
          Swal.fire(
            'Correcto',
            'Medios de pago actualizados exitosamente!',
            'success'
          )  
        }
      }, err => {
      });
  }
}

export class MedioPagoObject {
  id: number;
  value: string;
}
