import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';
import { RequestSearchService } from '../shared/requestSearchService';
import { requestSearchServiceUatp } from '../shared/requestSearchServiceUatp';
import { ResponseServiceEmision } from '../shared/responseserviceemision';
import { RequestServiceEmision } from '../shared/requestserviceemision';
import { RequestGenericEmision} from '../shared/request-generic-emision';
import { RequestReservasPurgadas } from '../shared/request-reservas-purgadas';
import { RequestSearchBooking} from '../shared/request-search-booking';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { ResponseDuplex } from '../shared/response-duplex';
import { ResponseServiceDuplex} from '../shared/responseServiceDuplex';
import { RequestServiceDuplex} from '../shared/requestServiceDuplex';
import { RequestServiceFPEmision } from '../shared/requestserviceFPemision';
import { ResponseServiceFPEmision } from '../shared/responseserviceFPemision';
import { RequestServiceTicketing } from '../shared/requestServiceTicketing';
import { ResponseServiceTicketing } from '../shared/responseServiceTicketing';
import { RequestReembolsosWP } from '../shared/request-reembolsos-wp';
import { RequestStatus } from '../shared/request-status';


@Injectable({
  providedIn: 'root'
})
export class RelanzamientosService {
  public urlEndpoint = environment.urlService;
  public urlEndpointReservasPrugadas = environment.urlServiceReservasPurgadas;
  public urlEndpointRefundsWP = environment.urlServiceRefundsWP;
  public urlEndpointVisionReport = environment.urlServiceVisionReport;
  private suscription = environment.ocpSuscription;
  private authorization = environment.authorization;
  private suscriptionReservasPurgadas = environment.ocpSuscriptionReservasPurgadas
  private httpHeaders; 



  constructor(private http: HttpClient) { }

  getRelanzamientos(request: RequestSearchService): Observable<any> {

    this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': this.suscription,'Operacion':'Consulta' });
    
    return this.http.post(this.urlEndpoint, request, { headers: this.httpHeaders })
      .pipe(map(response => response as any), timeout(30000), catchError(e => {
        Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
        return throwError(e);
      })
      );

  }


  sendEmisiones(request: RequestServiceEmision): Observable<ResponseServiceEmision> {

    this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': this.suscription, 'Operacion':'Emitir' });
    return this.http.post(this.urlEndpoint, request, { headers: this.httpHeaders })
      .pipe(map(response => response as ResponseServiceEmision), timeout(30000), catchError(e => {
        Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
        return throwError(e);
      })
      );
  }

  sendEmisionesGenericas(request: RequestGenericEmision): Observable<ResponseServiceEmision>{
    this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': this.suscription, 'Operacion':'EmisionGenerica' });

    return this.http.post(this.urlEndpoint, request,{ headers: this.httpHeaders})
      .pipe(map(response => response as ResponseServiceEmision), timeout(30000), catchError(e => {
        Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
        return throwError(e);
      })
      );
  }

  sendReservasPurgadas(request: RequestReservasPurgadas): Observable<object>{
    this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': this.suscriptionReservasPurgadas, Operacion: 'ReservasPurgadas' });

    return this.http.post(this.urlEndpointReservasPrugadas, request, { headers: this.httpHeaders})
      .pipe(map(response => response as object), timeout(900000), catchError(e => {
        Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
        return throwError(e);
      })
      );
  }

  searchBooking(request: RequestSearchBooking): Observable<any>{
    this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': this.suscription, 'Operacion':'Booking'});
    
    return this.http.post(this.urlEndpoint, request,{ headers: this.httpHeaders})
    .pipe(map(response => response as ResponseDuplex), timeout(30000), catchError(e => {
      Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
      return throwError(e);
    })
    );
  }

  sendElimnaDuplex(request: RequestServiceDuplex): Observable<ResponseServiceDuplex> {

    this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': this.suscription, 'Operacion':'Booking/Eliminar' });
    return this.http.post(this.urlEndpoint, request, { headers: this.httpHeaders })
      .pipe(map(response => response as ResponseServiceDuplex), timeout(30000), catchError(e => {
        Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
        return throwError(e);
      })
      );
  }

  sendEmisionesFPGenericas(request: RequestServiceFPEmision): Observable<ResponseServiceFPEmision>{
    this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': this.suscription, 'Operacion':'FormaPago' });

    return this.http.post(this.urlEndpoint, request,{ headers: this.httpHeaders})
      .pipe(map(response => response as ResponseServiceFPEmision), timeout(30000), catchError(e => {
        Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
        return throwError(e);
      })
      );
  }

  sendAdicionarTKXL(request: RequestServiceTicketing): Observable<ResponseServiceTicketing>{
    this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': this.suscription, 'Operacion':'AdicionarTKXL' });

    return this.http.post(this.urlEndpoint, request,{ headers: this.httpHeaders})
      .pipe(map(response => response as ResponseServiceTicketing), timeout(30000), catchError(e => {
        Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
        return throwError(e);
      })
      );
  }

  sendRefundsWP(request: RequestReembolsosWP): Observable<any>{
    this.httpHeaders = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': this.suscription,
      'Authorization': this.authorization, });

    return this.http.post(this.urlEndpointRefundsWP, request,{ headers: this.httpHeaders})
      .pipe(map(response => response as any), timeout(30000), catchError(e => {
        Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
        return throwError(e);
      })
      );
  }

  visionReport(request: RequestStatus): Observable<any>{
    const CryptoJS = require("crypto-js");
    const username = environment.cellpointUser;
    const password = environment.cellpointPassword;
    const rawSalt = CryptoJS.enc.Utf8.parse(username + password);
    const salt = CryptoJS.enc.Base64.stringify(rawSalt);
    const body =  '' + JSON.stringify(request);;
    const bodywithoutspace = body.replace(/\s+/g,'');
    const secToken = CryptoJS.SHA256(bodywithoutspace + salt);
    this.httpHeaders = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': this.suscription,
      'Authorization': this.authorization,
      'x-cpm-sec-token': secToken.toString()});

    return this.http.post(this.urlEndpointVisionReport, request,{ headers: this.httpHeaders})
      .pipe(map(response => response as any), timeout(30000), catchError(e => {
        Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
        return throwError(e);
      })
      );
  }
}
