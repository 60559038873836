import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, CdkDrag, CdkDropList, CdkDropListGroup, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { environment } from '../../../environments/environment';
import { Permisos, RequestGestionModulos } from '../../shared/permisos';
import { GestionModulosService } from '../../services/gestionmodulos.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-gestion-modulos',
  templateUrl: './gestion-modulos.component.html',
  styleUrls: ['./gestion-modulos.component.css']
})
export class GestionModulosComponent implements OnInit {

  routesList = [
    {path: 'monitoreo'},
    {path: 'relanzamientos', section:'monitoreo', name: 'Emisiones ViancaPay', groups:['b3243b64-1699-4745-83b8-1c0ab7aa9683']},
    {path: 'tarjetaGenerica', section:'monitoreo', name: 'Emisiones Tarjetas genericas', groups:['b3243b64-1699-4745-83b8-1c0ab7aa9683']},
    {path: 'duplex', section:'monitoreo', name: 'Eliminación Manual Reservas Duplex', groups:['b3243b64-1699-4745-83b8-1c0ab7aa9683']},
    {path: 'EmisionesFPGenerica', section:'monitoreo', name: 'Emisiones FP Generica', groups:['b3243b64-1699-4745-83b8-1c0ab7aa9683']},
    {path: 'adicionarTKXL', section:'monitoreo', name: 'Adicionar TKXL', groups:['b3243b64-1699-4745-83b8-1c0ab7aa9683']},
    {path: 'reservasPurgadas'},
    {path: 'purgadas', section:'reservasPurgadas', name: 'Carga Reservas purgadas', groups:['b3243b64-1699-4745-83b8-1c0ab7aa9683']},
    {path: 'archivoCargado', section:'reservasPurgadas', name: 'Consulta Archivo Cargado', groups:['b3243b64-1699-4745-83b8-1c0ab7aa9683']},
    {path: 'cancelaciones'},
    {path: 'CancelacionesUatp', section:'cancelaciones', name: 'Cancelar autorizaciones de UATP', groups:['b3243b64-1699-4745-83b8-1c0ab7aa9683']},
    {path: 'AutorizacionesWorldpay', section:'cancelaciones', name: 'Cancelar autorizaciones de Worldpay', groups:['b3243b64-1699-4745-83b8-1c0ab7aa9683']},
    {path: 'gestion'},
    {path: 'medios-pago-gestion', section:'gestion', name: 'Act/Desact Medios de Pago', groups:['b3243b64-1699-4745-83b8-1c0ab7aa9683']},
    {path: 'bancos-pse-gestion', section:'gestion', name: 'Act/Desact Bancos PSE', groups:['b3243b64-1699-4745-83b8-1c0ab7aa9683']},
    {path: 'reembolsos'},
    {path: 'reembolsosWorldpay', section:'reembolsos', name: 'Reembolsos Worldpay', groups:['b3243b64-1699-4745-83b8-1c0ab7aa9683']},
    {path: 'administrarModulos'},
    {path: 'gestionModulos'},
    {path: 'historialLogs'}
  ];
  modulosGestion = [];
  monitoreo = [];
  mediosPago = [];
  listPermisosFinal: Permisos[] = []
  requestGestionModulos : RequestGestionModulos;

  constructor(private gestionModulosService: GestionModulosService, private spinnerService: NgxSpinnerService) {
    this.requestGestionModulos = new RequestGestionModulos();
    this.requestGestionModulos.request = [];
  }

  ngOnInit(): void {
    this.gestionModulosService.getGestionModulos().subscribe(res => {
      this.routesList.forEach(i => {
        res.request.forEach(element => {
          if (element.path === i.path && element.groups.includes('ca0856f5-14a4-4ef4-93b3-1459a1a15b55') && i.name) {
            this.monitoreo.push(i.name);
            if (element.groups.includes('6937f81b-493c-4086-9fd8-283e35bf2b71')){
              this.mediosPago.push(i.name);  
            }
          } else if (element.path === i.path && element.groups.includes('6937f81b-493c-4086-9fd8-283e35bf2b71') && i.name) {
            this.mediosPago.push(i.name);
          } else if (element.path === i.path && i.name) {
            this.modulosGestion.push(i.name);
          }
        });
      });
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  cloneItem(item: string): void {
    let count = 0,
        posicionFinal = 0;
    this.modulosGestion.forEach(e => {
      count++;
      if (e === item) posicionFinal = count;
    });
    this.modulosGestion.splice(posicionFinal, 0, item);
  }

  gestionarPermisos(): void {
    this.spinnerService.show();
    this.listPermisosFinal = [];

    this.routesList.forEach(i => {
      i.groups = [];
      i.groups.push('b3243b64-1699-4745-83b8-1c0ab7aa9683');

      this.monitoreo.forEach(e => {
          if (e.includes(i.name)){
            i.groups.push('ca0856f5-14a4-4ef4-93b3-1459a1a15b55');
            switch (i.section) {
              case 'monitoreo':
                this.routesList.forEach(item => {
                  if (item.path === 'monitoreo' && item.groups.indexOf('ca0856f5-14a4-4ef4-93b3-1459a1a15b55') === -1){
                    item.groups.push('ca0856f5-14a4-4ef4-93b3-1459a1a15b55');
                  }
                });
                break;
              case 'reservasPurgadas':
                this.routesList.forEach(item => {
                  if (item.path === 'reservasPurgadas' && item.groups.indexOf('ca0856f5-14a4-4ef4-93b3-1459a1a15b55') === -1){
                    item.groups.push('ca0856f5-14a4-4ef4-93b3-1459a1a15b55');
                  }
                });
                break;
              case 'cancelaciones':
                this.routesList.forEach(item => {
                  if (item.path ==='cancelaciones' && item.groups.indexOf('ca0856f5-14a4-4ef4-93b3-1459a1a15b55') === -1){
                    item.groups.push('ca0856f5-14a4-4ef4-93b3-1459a1a15b55');
                  }
                });
                break;
              case 'gestion':
                this.routesList.forEach(item => {
                  if (item.path === 'gestion' && item.groups.indexOf('ca0856f5-14a4-4ef4-93b3-1459a1a15b55') === -1){
                    item.groups.push('ca0856f5-14a4-4ef4-93b3-1459a1a15b55');
                  }
                });
                break;
              case 'reembolsos':
                this.routesList.forEach(item => {
                  if (item.path === 'reembolsos' && item.groups.indexOf('ca0856f5-14a4-4ef4-93b3-1459a1a15b55') === -1){
                    item.groups.push('ca0856f5-14a4-4ef4-93b3-1459a1a15b55');
                  }
                });
                break;
              default:
                break;
            }
          }
      });

      this.mediosPago.forEach(e => {
          if (e.includes(i.name)){
            i.groups.push('6937f81b-493c-4086-9fd8-283e35bf2b71');
            switch (i.section) {
              case 'monitoreo':
                this.routesList.forEach(item => {
                  if (item.path === 'monitoreo' && item.groups.indexOf('6937f81b-493c-4086-9fd8-283e35bf2b71') === -1){
                    item.groups.push('6937f81b-493c-4086-9fd8-283e35bf2b71');
                  }
                });
                break;
              case 'reservasPurgadas':
                this.routesList.forEach(item => {
                  if (item.path === 'reservasPurgadas' && item.groups.indexOf('6937f81b-493c-4086-9fd8-283e35bf2b71') === -1){
                    item.groups.push('6937f81b-493c-4086-9fd8-283e35bf2b71');
                  }
                });
                break;
              case 'cancelaciones':
                this.routesList.forEach(item => {
                  if (item.path === 'cancelaciones' && item.groups.indexOf('6937f81b-493c-4086-9fd8-283e35bf2b71') === -1){
                    item.groups.push('6937f81b-493c-4086-9fd8-283e35bf2b71');
                  }
                });
                break;
              case 'gestion':
                this.routesList.forEach(item => {
                  if (item.path === 'gestion' && item.groups.indexOf('6937f81b-493c-4086-9fd8-283e35bf2b71') === -1){
                    item.groups.push('6937f81b-493c-4086-9fd8-283e35bf2b71');
                  }
                });
                break;
              case 'reembolsos':
                this.routesList.forEach(item => {
                  if (item.path === 'reembolsos' && item.groups.indexOf('6937f81b-493c-4086-9fd8-283e35bf2b71') === -1){
                    item.groups.push('6937f81b-493c-4086-9fd8-283e35bf2b71');
                  }
                });
                break;
              default:
                break;
            }
          }
      });

    });

    this.routesList.forEach(item => {
      let listPermisos: Permisos = new Permisos();
      listPermisos.path = item.path;
      listPermisos.groups = item.groups;
      this.listPermisosFinal.push(listPermisos);
    });

    this.requestGestionModulos.request = this.listPermisosFinal;
    
    this.gestionModulosService.sendGestionModulos(this.requestGestionModulos).subscribe(res => {
      Swal.fire('Gestión de módulos', 'La gestión de módulos se ha ejecutado satisfactoriamente', 'success');
      this.spinnerService.hide();
    }, err => {
      console.log(err);
      Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
      this.spinnerService.hide();
    });
  }

}
