import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'app-relanzamientos-emision';

  constructor(
    @Inject(DOCUMENT) document: any,
    public authService: AuthService) {}

  public pathExterno: boolean = false;

  ngOnInit(): void {
    document.location.pathname.includes("modulo-externo") ? 
      this.pathExterno = true : this.authService.updateLoggedInStatus();
  }

}
