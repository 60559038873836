import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { formatDate, UpperCasePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { RequestHistorialLogs, FieldsLogs } from '../../shared/historial-logs';
import { GestionModulosService } from '../../services/gestionmodulos.service';

@Component({
  selector: 'app-historial-logs',
  templateUrl: './historial-logs.component.html',
  styleUrls: ['./historial-logs.component.css']
})
export class HistorialLogsComponent implements OnInit {
  @ViewChild('mdbTablePaginationResult', { static: true }) mdbTablePaginationResult: MdbTablePaginationComponent;
  @ViewChild('tableEl', { static: true }) tableEl: MdbTableDirective;

  public dateInitial: NgbDateStruct | null;
  public dateFinal: NgbDateStruct | null;
  public startDate: Date | null;
  public endDate: Date | null;
  public startAt = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 0, 0);
  public endAt = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 23, 59);
  public min = new Date();
  public max = new Date();
  public previous: any = [];
  public filterUser: string | null;
  public listLogs: FieldsLogs[] = [];
  private requestHistorialLogs: RequestHistorialLogs;

  constructor(
    private spinnerService: NgxSpinnerService,
    private cdRef: ChangeDetectorRef,
    private gestionModulosService: GestionModulosService) {
    this.requestHistorialLogs = new RequestHistorialLogs();
    this.startDate = null;
    this.endDate = null;
    this.filterUser = null;
  }

  ngOnInit(): void {

  }

  searchLogs(): any {
    this.spinnerService.show();
    this.listLogs = [];
    
    if (this.filterUser === null) {
      this.requestHistorialLogs.user = '';
    } else {
      this.requestHistorialLogs.user = this.filterUser.toLowerCase();
    }
    
    //Se agregan 5 horas a las fechas de inicio y fin para transformar las fechas en hora zulu    
    if (this.startDate === null) {
      this.requestHistorialLogs.startDate = '';
    } else {
      let startDateAux = new Date(this.startDate);
      startDateAux.setHours(this.startDate.getHours() + 5);
      let fechInicio = this.startDate.toLocaleDateString('en-US') + " " + startDateAux.getHours().toString() + ":" + startDateAux.getMinutes().toString();
      this.requestHistorialLogs.startDate = formatDate(fechInicio, 'dd/MM/yyyy HH:mm', 'en-US');
    }
    if (this.endDate === null) { 
      this.requestHistorialLogs.endDate = '';
    } else {
      let endDateAux = new Date(this.endDate);
      endDateAux.setHours(this.endDate.getHours() + 5);
      let fechFinal = this.endDate.toLocaleDateString('en-US') + " " + endDateAux.getHours().toString() + ":" + endDateAux.getMinutes().toString();
      this.requestHistorialLogs.endDate = formatDate(fechFinal, 'dd/MM/yyyy HH:mm', 'en-US');
    }

    this.gestionModulosService.searchHistorailLogs(this.requestHistorialLogs)
      .subscribe(logs => {
        if (logs !== null) {
          if (logs.data.length !== 0) {
            logs.data.forEach(element => {
              const reponseLogs = new FieldsLogs();
              reponseLogs.idSeguimiento = element.IdSeguimiento;
              reponseLogs.usuario = element.Usuario;
              reponseLogs.operacion = element.Operacion;
              reponseLogs.fechaActualizacion = element.FechaActualizacion;
              this.listLogs.push(reponseLogs);
            });
            this.configureTableResult();
          } else {
            Swal.fire('La búsqueda no arrojo resultados', '', 'info')
          }
        } else {
          Swal.fire('La búsqueda no arrojo resultados', '', 'info');
        }
        this.spinnerService.hide();
      }, err => {
        this.spinnerService.hide();
      });
  }
 
  buttonDisabled(): boolean {
    if ( (this.filterUser == null || this.filterUser == '') && this.startDate == null && this.endDate == null) {
      return true;
    } else {
      return false;
    }
  }

  cambiafecha(): void {
    if (this.startDate !== null) {
      this.endAt = new Date(this.startDate);
      this.min = this.endAt;

      if (this.endDate !== null && this.startDate > this.endDate) {
        this.endDate = null;
        this.dateFinal = null;
      }
    }
  }

  configureTableResult(): void {
    this.mdbTablePaginationResult.setMaxVisibleItemsNumberTo(10);
    this.tableEl.setDataSource(this.listLogs);
    this.listLogs = this.tableEl.getDataSource();
    this.previous = this.tableEl.getDataSource();
    
    this.mdbTablePaginationResult.calculateFirstItemIndex();
    this.mdbTablePaginationResult.calculateLastItemIndex();
    this.mdbTablePaginationResult.firstPage();
    this.cdRef.detectChanges();
  }

}
