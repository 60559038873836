import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { requestSearchServiceUatp } from '../shared/requestSearchServiceUatp';
import { Requestservicevoid } from '../shared/requestservicevoid';
import { requestAuthService } from '../shared/requestAuthService';


import { ResponseServiceVoid } from '../shared/responseservicevoid';
import { ResponseAuthService } from '../shared/responseAuthService';
import { createNoSubstitutionTemplateLiteral } from 'typescript';


@Injectable({
  providedIn: 'root'
})
export class CancelacionesService {
  public urlEndpoint = environment.urlService;
  
  public urlEndpointUatp = environment.urlServiceUatp;
  public urlEndpointUatpVoid = environment.urlServiceVoid;
  public urlAuthorization = environment.urlAuthorization;

  private suscription = environment.ocpSuscription;
  private authorization = environment.authorization;
  private httpHeaders; 
  private authorizationToken: string;

  constructor(private http: HttpClient) { }

 getAuthorization(){
  
  this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Credentials': 'true',
  'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS',
  'Ocp-Apim-Subscription-Key': this.suscription,
  'Access-Control-Allow-Headers' : 'Origin, Content-Type, Accept, X-Custom-Header, Upgrade-Insecure-Requests'});
  
  var request = new requestAuthService();
  let body =  new HttpParams()
.set('grant_type', "client_credentials")
.set('client_id', "30ab7ca7-3cc6-4615-8f19-f2fe044ac32f")
.set('client_secret', "Osd7Q~7bPb167nWka0eVrMPPmuYXkfuJ1PU-5")
.set('resource', "api://30ab7ca7-3cc6-4615-8f19-f2fe044ac32f");


  return this.http.post(this.urlAuthorization, body.toString(),{ headers: this.httpHeaders })
  .pipe(map(response => response as ResponseAuthService), timeout(30000), catchError(e => {

    Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
    return throwError(e);
  })
  );

 }



  Authorization():void{
  this.getAuthorization()
  .subscribe(response => {
    if(response != null){
      debugger;
      var auth = new ResponseAuthService();
      this.authorizationToken = auth.access_token;
    }

  }
    );
 }
  
  getPnrUatp(request: requestSearchServiceUatp): Observable<any> {
    debugger;
   // this.Authorization();
  //  this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json','Ocp-Apim-Subscription-Key': this.suscription, 'Authorization':this.authorization, 'metodo':'consulta' });
    this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json','Ocp-Apim-Subscription-Key': this.suscription,  'metodo':'consulta' });
  
    return this.http.post(this.urlEndpointUatp, request, { headers: this.httpHeaders })
      .pipe(map(response => response as any), timeout(30000), catchError(e => {
  
        Swal.fire('Error de comunicación', e.message, 'error');
        return throwError(e);
      })
      );
      return null;
  }

  
  sendVoid(request: Requestservicevoid): Observable<ResponseServiceVoid> {

    this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': this.suscription, 'Authorization':this.authorization, 'metodo':'void' });
    return this.http.post(this.urlEndpointUatpVoid, request, { headers: this.httpHeaders })
      .pipe(map(response => response as ResponseServiceVoid), timeout(30000), catchError(e => {
        Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
        return throwError(e);
      })
      );
  }

}
