import { Pnr } from './requestServiceTicketing';
export class ResponseServiceTicketing {
    Success: Success[];
    Failed: Failed[];
    validationError : boolean;
}

export class Success {
    pnr: string;
    lastName: string;
    officeId : string;
}

export class Failed {
    pnr: string;
    lastName: string;
    officeId : string;
    error: string;
    field: string;
    value: string;
    message: string;
}

export class PnrStateful {
    pnr: string;
    lastName: string;
    officeId : string;
    status:  string;
}
