<app-navbar></app-navbar>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="container">


    <div [hidden]="bancos?.length == 0"> 
        <div class="card border-stylish-color-dark my-5" >
        <div class="card-header text-light"></div>
        <div class="card-body text-app-color">
            <h5 class="card-title">Listado de Bancos</h5>
            <div class="alert alert-info" *ngIf="bancos?.length == 0">
                No se encontraron bancos
            </div>
            <div class="col-auto">
                <div class="float-right">
                </div>

                <table mdbTable #tableEl="mdbTable" class="table table-bordered table-striped table-hover table-sm table-responsive-lg ">
                    <thead>
                        <tr>
                            <th class="text-center font-weight-bold">Codigo</th>
                            <th class="text-center font-weight-bold">Nombre</th>
                            <th class="text-center font-weight-bold">Canales</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let banco of bancos">
                            <td scope="row" class="text-center">{{banco.codigo}}</td>
                            <td scope="row" class="text-center">{{banco.nombre}}</td>                      
                            <td scope="row" class="text-center">
                                <button type="button" class="btn btn-danger" 
                                    data-toggle="modal" data-target="#ModalCanales" 
                                    (click)='SeleccionaBancoGestion(banco.bloqueadoEnCanales);bancoSeleccionado=banco.codigo'>
                                    Gestionar
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal" id="ModalCanales">
        <div class="modal-dialog">
        <div class="modal-content">
    
            <!--  Header -->
            <div class="modal-header text-center">
            <h4 class="modal-title w-100">Canales</h4>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
    
            <!--  Body -->
            <div class="modal-body">
                <table class="table table-striped">
        <thead>
            <tr>
                <th class="text-center font-weight-bold">Canal</th>
                <th class="text-center font-weight-bold">Estado</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let canal of canalesBancoSeleccionado">
                <td scope="row" class="text-center">{{canal.nombre}}</td>
                <td scope="row" class="text-center">
                    <input type="checkbox" [checked]="canal.estado"
                    [nombre]="canal.nombre"
                    (change)="AgregarCambioBanco($event)">
                </td>
            </tr>
        </tbody>
        </table>
        </div>    
            <!-- Footer -->
            <div class="modal-footer">
            <button type="button" class="btn btn-danger text-center" data-dismiss="modal" #myModalClose
                    (click)='UpdateCanalesBanco()'>
                    Guardar Cambios
            </button>
            </div>    
        </div>
        </div>
        </div>
    </div>

</div>