export class Pnr {
    pnr: string;
    lastName: string;
    franquicia: string;
    oid: string;
    paymentMethod: string;
    cus: string;
}

export class RequestServiceFPEmision {
    request: Pnr[];
}
