import { ReembolsosWP, Amount } from './request-reembolsos-wp';

export class ResponseServiceRelunch {
    Success: ReembolsosWP[];
    Failed: Failed[];
}

export class Failed {
    pnr: string;
    field: string;
    value: string;
    message: string;
}

export class StatusFinal {
    canal: string;
    pnr: string;
    estatus: string;
}
