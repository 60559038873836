export class PnrVoid {
    moneda: string;
    pais: string;
    canal: string;
    pnr: string;
}

export class RequestServiceVoidWorldpay {
    request: PnrVoid[];
}
