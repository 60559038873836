export class PnrsVoidWorldpay {
    moneda: string;
    pais: string;
    canal: string;
    pnr: string;
    fechaTransaccion: string;
    selected: boolean | false;
}

export class Response {
    pnrsCancelarUatp: PnrsVoidWorldpay[];
}
