export class Relanzamiento {
    pnr: string;
    estado: string;
    formaDePago: string;
    monto: number;
    fechaTransaccion: Date;
    nombres: string;
    correoElectronico: string;
    direccion: string;
    telefono: number;
    categoriaAmadeus: number;
    colaAmadeus: number;
    selected: boolean | false;
    agregarFP: boolean | false;
    lastName: string;
    tipoFallo: number;
    blockSelected: boolean | false;
    blockFP: boolean | false;
    oidEmision: string;
    esTimeToThink: string;
    lenguaje: string;

}

export class Response {

    relanzamientos: Relanzamiento[];
}
