import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import {TarjetasGenericas, Response} from '../../shared/tarjetas-genericas';
import { RequestGenericEmision, Pnr } from '../../shared/request-generic-emision';
import { RelanzamientosService } from '../../services/relanzamientos.service';
import { ResponseServiceEmision, Success, Failed, PnrStateful } from '../../shared/responseserviceemision';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import * as Papa from 'papaparse';

@Component({
  selector: 'app-tarjetas-genericas',
  templateUrl: './tarjetas-genericas.component.html',
  styleUrls: ['./tarjetas-genericas.component.css']
})
export class TarjetasGenericasComponent implements OnInit, AfterViewInit {
  @ViewChild('mdbTablePagination', { static: true })  mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('mdbTablePaginationResult', { static: true })  mdbTablePaginationResult: MdbTablePaginationComponent;
  @ViewChild('tableEl', { static: true }) tableEl: MdbTableDirective;
  @ViewChild('tableE2', { static: true }) tableE2: MdbTableDirective;
  @Output() newItemEvent = new EventEmitter<RequestGenericEmision>();
  listTGenericas : TarjetasGenericas[]=[];
  requestGenericEmision: RequestGenericEmision;
  resultEmision: ResponseServiceEmision;
  listPnrSucces: Success;
  listPnrFallidos: Failed;
  listpnrEmitidos: PnrStateful[] = [];
  @ViewChild('form') form;
  previous: any = [];

  constructor(private relanzamientosService: RelanzamientosService, private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.requestGenericEmision = new RequestGenericEmision();
    this.requestGenericEmision.request = [];
   }

  ngOnInit(): void {
  }

  onChange(files: File[]){
    this.listTGenericas = [];
    this.listpnrEmitidos = [];
    var ext =this.validaFile(files);
    if (ext){
      if(files[0]){
        Papa.parse(files[0], {
          header: true,
          skipEmptyLines: true,
          complete: (result,file) => {
            console.log(result);
            var cabeceras = this.validaCabeceras(result.meta.fields);
            if(cabeceras){
              this.listTGenericas = result.data;
                this.listTGenericas.forEach(element => {
                  if(element.estado == 0){
                    element.tipoEstado = "Emisión";
                  }else{
                    element.tipoEstado = "FP y Emisión";
                  }
                  if(element.franquicia != "" || element.cus === undefined){
                    element.cus = null;
                  }
                  if(element.ttt == 1){
                    element.esTimeToThink = "SI";
                  }
                  else{
                    element.esTimeToThink = "NO";
                  }
                });
                this.configureTable();
            }
            else{
              Swal.fire('El archivo no cuenta con las cabeceras correspondientes, favor de validar', '', 'info');
      this.spinnerService.hide();
      this.reset();
            }
            
            
          }
        });
      }
    }
    else{
      Swal.fire('El archivo no es de tipo txt o un csv, intente con otro archivo', '', 'info');
      this.spinnerService.hide();
      this.reset();
    }
  }
  
  configureTable(): void {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);
    this.tableEl.setDataSource(this.listTGenericas);
    this.listTGenericas = this.tableEl.getDataSource();
    this.previous = this.tableEl.getDataSource();
    
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.mdbTablePagination.firstPage();
    this.cdRef.detectChanges();
  }

  reset() {
    this.form.nativeElement.reset()
  }

  validaFile(files: File[]){
    if (files[0].name.includes('.csv') || files[0].name.includes('.txt') || files[0].name.includes('.TXT') || files[0].name.includes('.CSV')){
      return true;
    }
    else{
      return false;
    }
  }

  validaCabeceras(file:any[]){
    if (file.includes('pnr') && file.includes('apellidos') && file.includes('franquicia') && file.includes('officeID') && file.includes('medioPago') && file.includes('estado') && file.includes('cus') && file.includes('lang') && file.includes('ttt') && file.includes('codAuth')){
      return true;
    }
    else{
      return false;
    }
  }

  sendEmisiones(): void {
    let mensaje: string ="Fallido: ";
    this.spinnerService.show();
    this.previous = [];
    this.requestGenericEmision.request = this.getListPnrs();
    //this.listTGenericas = [];
    this.relanzamientosService.sendEmisionesGenericas(this.requestGenericEmision).subscribe(response => {
      
      this.resultEmision = response;

      this.resultEmision.Failed.forEach(element => {
        // tslint:disable-next-line: prefer-const
        let statusPnr: PnrStateful = new PnrStateful();
        statusPnr.Pnr = element.pnr;
        statusPnr.lasName = element.lastName; 
        if(this.resultEmision.validationError == true){
          statusPnr.status = mensaje.concat('Campo: ' + element.field + ', Valor: ' + element.value + ', Mensaje: ' + element.message);
        }
        else {
          statusPnr.status = mensaje.concat(element.error);
        }
        this.listpnrEmitidos.push(statusPnr);
      });
      this.resultEmision.Success.forEach(element => {
        // tslint:disable-next-line: prefer-const
        let statusPnr: PnrStateful = new PnrStateful();
        statusPnr.Pnr = element.pnr;
        statusPnr.lasName = element.lastName;
        statusPnr.status = 'Emitido';
        this.listpnrEmitidos.push(statusPnr);
      });
      
      this.configureTableResult();  
      this.spinnerService.hide();
    }, err => {
      console.log(err);
      this.spinnerService.hide();
    });
  }

  getListPnrs(): Pnr[] {
    let listPnrSelected: Pnr[] = [];
    for (const item of this.listTGenericas) {
      const pnrSelected = new Pnr();
      pnrSelected.pnr = item.pnr.trim();
      pnrSelected.lastName = item.apellidos.trim();
      pnrSelected.FP = item.estado;
      pnrSelected.oid = item.officeID.trim();
      pnrSelected.franquicia = item.franquicia.trim();
      pnrSelected.paymentMethod = item.medioPago.trim();
      item.cus != null ? pnrSelected.cus = item.cus.trim() : pnrSelected.cus = item.cus;
      pnrSelected.lang = item.lang.trim();
      pnrSelected.ttt = item.ttt;
      pnrSelected.codAuth = item.codAuth;
      listPnrSelected.push(pnrSelected);
    }
    return listPnrSelected;
  }

  configureTableResult(): void {
    this.mdbTablePaginationResult.setMaxVisibleItemsNumberTo(10);
    this.tableE2.setDataSource(this.listpnrEmitidos);
    this.listpnrEmitidos = this.tableE2.getDataSource();
    this.previous = this.tableE2.getDataSource();

    this.mdbTablePaginationResult.calculateFirstItemIndex();
    this.mdbTablePaginationResult.calculateLastItemIndex();
    this.mdbTablePaginationResult.firstPage();
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {

  }

}
