import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { RequestGestionModulos } from '../shared/permisos';
import { RequestHistorialLogs, ResponseHistorialLogs } from '../shared/historial-logs';


@Injectable({
  providedIn: 'root'
})

export class GestionModulosService {
  public urlEndpointGestionModulos = environment.urlServiceGestionModulos;
  public urlEndpointHistorialLogs = environment.urlServiceHistorialLogs;
  private suscription = environment.ocpSuscription;
  private authorization = environment.authorization;
  private httpHeaders; 



  constructor(private http: HttpClient) { }

  getGestionModulos(): Observable<any> {
    this.httpHeaders = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': this.suscription});

    return this.http.get(this.urlEndpointGestionModulos, { headers: this.httpHeaders })
      .pipe(map(response => response as any), timeout(30000), catchError(e => {
        Swal.fire('Error de comunicación', 'No se pudieron cargar los permisos de usuario, por favor intente de nuevo', 'error');
        return throwError(e);
      })
      );
  }

  sendGestionModulos(request: RequestGestionModulos): Observable<any>{
    this.httpHeaders = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': this.suscription,
      'Authorization': this.authorization});

    return this.http.post(this.urlEndpointGestionModulos, request,{ headers: this.httpHeaders})
      .pipe(map(response => response as any), timeout(30000), catchError(e => {
        Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
        return throwError(e);
      })
      );
  }

  searchHistorailLogs(request: RequestHistorialLogs): Observable<any>{
    this.httpHeaders = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': this.suscription,
      'Authorization': this.authorization});

    return this.http.post(this.urlEndpointHistorialLogs, request,{ headers: this.httpHeaders})
      .pipe(map(response => response as ResponseHistorialLogs), timeout(30000), catchError(e => {
        Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
        return throwError(e);
      })
      );
  }
}
