import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { Ticketing, Response} from '../../shared/ticketing';
import { RelanzamientosService } from '../../services/relanzamientos.service';
import { ResponseServiceTicketing, Success, Failed, PnrStateful } from '../../shared/responseServiceTicketing';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import * as Papa from 'papaparse';
import { RequestServiceTicketing , Pnr} from 'src/app/shared/requestServiceTicketing';
import { isNull } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-ticketing',
  templateUrl: './ticketing.component.html',
  styleUrls: ['./ticketing.component.css']
})
export class TicketingComponent implements OnInit, AfterViewInit {
  @ViewChild('mdbTablePagination', { static: true })  mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('mdbTablePaginationResult', { static: true })  mdbTablePaginationResult: MdbTablePaginationComponent;
  @ViewChild('tableEl', { static: true }) tableEl: MdbTableDirective;
  @ViewChild('tableE2', { static: true }) tableE2: MdbTableDirective;
  @Output() newItemEvent = new EventEmitter<RequestServiceTicketing>();

  listTicketing : Ticketing[]=[];
  requestTicketing : RequestServiceTicketing;
  resultAddTicketing : ResponseServiceTicketing;
  listPnrSucces: Success;
  listPnrFallidos: Failed;
  listpnrEmitidos: PnrStateful[] = [];
  @ViewChild('form') form;
  previous: any = [];
  listValidacionesFallidas = [];

  constructor(private relanzamientosService: RelanzamientosService, private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.requestTicketing = new RequestServiceTicketing();
    this.requestTicketing.request = [];
   }

  ngOnInit(): void {
  }

  onChange(files: File[]){
    this.listTicketing = [];
    this.listpnrEmitidos = [];
    var ext =this.validaFile(files);
    if (ext){
      if(files[0]){
        Papa.parse(files[0], {
          header: true,
          skipEmptyLines: true,
          complete: (result,file) => {
            console.log(result);
            var cabeceras = this.validaCabeceras(result.meta.fields);
            if(cabeceras){
                this.listTicketing = result.data;
                this.configureTable();
              }
            else{
              Swal.fire('El archivo no cuenta con las cabeceras correspondientes, por favor validar', '', 'info');
              this.spinnerService.hide();
              this.reset();
            }
          }
        });
      }
    }
    else{
      Swal.fire('El archivo no es de tipo csv, intente con otro archivo', '', 'info');
      this.spinnerService.hide();
      this.reset();
    }
  }
  
  configureTable(): void {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);
    this.tableEl.setDataSource(this.listTicketing);
    this.listTicketing = this.tableEl.getDataSource();
    this.previous = this.tableEl.getDataSource();
    
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.mdbTablePagination.firstPage();
    this.cdRef.detectChanges();
  }

  reset() {
    this.form.nativeElement.reset()
  }

  validaFile(files: File[]){
    if (files[0].name.includes('.csv') || files[0].name.includes('.CSV')){
      return true;
    }
    else{
      return false;
    }
  }

  validaCabeceras(file:any[]){
    if (file.includes('pnr') && file.includes('apellidos') && file.includes('officeID')){ 
      return true;
    }
    else{
      return false;
    }
  }

  sendEmisiones(): void {
    let mensaje: string ="Fallido: ";
    this.spinnerService.show();
    this.previous = [];
    this.requestTicketing.request = this.getListPnrs();
    //this.listTicketing = [];
    this.relanzamientosService.sendAdicionarTKXL(this.requestTicketing).subscribe(response => {
      this.resultAddTicketing = response;
      this.resultAddTicketing.Failed.forEach(element => {
        let statusPnr: PnrStateful = new PnrStateful();
        
        if(this.resultAddTicketing.validationError == true){
          console.log(this.resultAddTicketing.validationError);
          for(const item of this.requestTicketing.request){
            if(element.value == item.pnr ){
              statusPnr.pnr = item.pnr;
              statusPnr.lastName = item.lastName;
              statusPnr.officeId = item.officeId;
            }
          }
          statusPnr.status = mensaje.concat(' Campo: ' + element.field + ', Valor: ' + element.value + ', Mensaje: ' + element.message);
          console.log(statusPnr);
        }
        else{
          statusPnr.pnr = element.pnr;
          statusPnr.lastName = element.lastName;
          statusPnr.officeId = element.officeId;
          statusPnr.status = mensaje.concat(element.error);
        }
        this.listpnrEmitidos.push(statusPnr);
      });
      this.resultAddTicketing.Success.forEach(element => {
        // tslint:disable-next-line: prefer-const
        let statusPnr: PnrStateful = new PnrStateful();
        statusPnr.pnr = element.pnr;
        statusPnr.lastName = element.lastName;
        statusPnr.officeId = element.officeId;
        statusPnr.status = 'Reservas procesadas exitosamente';
        this.listpnrEmitidos.push(statusPnr);

      });
      
      this.configureTableResult();  
      this.spinnerService.hide();
    }, err => {
      console.log(err);
      this.spinnerService.hide();
    });
  }

  getListPnrs(): Pnr[] {
    let listPnrSelected: Pnr[] = [];
    for (const item of this.listTicketing) {
      const pnrSelected = new Pnr();
      pnrSelected.pnr = item.pnr;
      pnrSelected.lastName = item.apellidos;
      pnrSelected.officeId = item.officeID;
      console.log(pnrSelected);
      listPnrSelected.push(pnrSelected);
    }
    return listPnrSelected;
  }

  configureTableResult(): void {
    this.mdbTablePaginationResult.setMaxVisibleItemsNumberTo(10);
    this.tableE2.setDataSource(this.listpnrEmitidos);
    this.listpnrEmitidos = this.tableE2.getDataSource();
    this.previous = this.tableE2.getDataSource();

    this.mdbTablePaginationResult.calculateFirstItemIndex();
    this.mdbTablePaginationResult.calculateLastItemIndex();
    this.mdbTablePaginationResult.firstPage();
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {

  }

}
