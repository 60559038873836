import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { RequestSearchServiceWorldpay } from '../../shared/request-search-service-worldpay';
import { formatDate, UpperCasePipe } from '@angular/common';
import { WorldpayService } from '../../services/worldpay.service';
import { PnrsVoidWorldpay } from '../../shared/pnrs-void-worldpay';
import Swal from 'sweetalert2';
import { PnrVoid, RequestServiceVoidWorldpay } from '../../shared/request-service-void-worldpay';
import { ResponseServiceVoidWorldpay, Failed, PnrStateful, Success } from '../../shared/response-service-void-worldpay';

@Component({
  selector: 'app-worldpay-auth-void',
  templateUrl: './worldpay-auth-void.component.html',
  styleUrls: ['./worldpay-auth-void.component.css']
})
export class WorldpayAuthVoidComponent implements OnInit {
  @ViewChild('mdbTablePaginationResult', { static: true }) mdbTablePaginationResult: MdbTablePaginationComponent;
  @ViewChild('tableEl', { static: true }) tableEl: MdbTableDirective;
  @ViewChild('tableE2', { static: true }) tableE2: MdbTableDirective;
  @ViewChild('mdbTablePagination', { static: true }) mdbTablePagination: MdbTablePaginationComponent;


  public dateInitial: NgbDateStruct | null;
  public dateFinal: NgbDateStruct | null;
  public startDate: Date | null;
  public endDate: Date | null;
  public startAt = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 0, 0);
  public endAt = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 23, 59);
  public min = new Date();
  public max = new Date();
  public filterPnr: string | null;
  public selectedAll: any;
  public listPnrs: PnrsVoidWorldpay[] = [];
  public listpnrEmitidos: PnrStateful[] = [];
  public blockAllFP: boolean;
  public selectedAItem: boolean;
  public selectedAItemFP: boolean;
  public previous: any = [];
  private requestSearch: RequestSearchServiceWorldpay;
  private requestVoid: RequestServiceVoidWorldpay;
  private resultVoid: ResponseServiceVoidWorldpay;

  constructor(
    private service: WorldpayService,
    private spinnerService: NgxSpinnerService,
    private cdRef: ChangeDetectorRef) {
    this.requestSearch = new RequestSearchServiceWorldpay();
    this.requestVoid = new RequestServiceVoidWorldpay();
    this.selectedAItem = false;
    this.selectedAItemFP = false;
  }

  ngOnInit(): void {
  this.tableEl = this.tableEl; 

  }

  claerdp(): void {
    this.dateInitial = null;
    this.dateFinal = null;
    // this.requestService.FechaInicio = null;
    // this.requestService.FechaFin = null;
    this.startDate = null;
    this.endDate = null;
  }

  cambiafecha(): void {
    if (this.startDate !== null) {
      this.endAt = new Date(this.startDate);
      this.min = this.endAt;

      if (this.endDate !== null && this.startDate > this.endDate) {
        this.endDate = null;
        this.dateFinal = null;
      }
    }
  }

  clearPnr(): void {
    this.filterPnr = null;
    this.requestSearch.PNR = [];
  }

  findPnrs(): any {
    this.selectedAll = false;
    this.spinnerService.show();
    this.listpnrEmitidos = [];
    this.listPnrs = [];
    this.selectedAll = false;
    this.blockAllFP = false;

    if (this.filterPnr !== null) {
      this.requestSearch.PNR[0] = this.filterPnr;
      this.requestSearch.FechaInicio = '';
      this.requestSearch.FechaFin = '';
    } else {
      //Se agregan 5 horas a las fechas de inicio y fin para transformar las fechas en hora zulu
      if (this.startDate === null) this.startDate = new Date();
      if (this.endDate === null) {
        this.endDate = new Date();
        this.endDate.setTime(this.startDate.getTime() + 1 * 60 * 60 * 1000);
      }

      var startDateAux = new Date(this.startDate);
      startDateAux.setHours(this.startDate.getHours() + 5);
      var endDateAux = new Date(this.endDate);
      endDateAux.setHours(this.endDate.getHours() + 5);
      var fechInicio = this.startDate.toLocaleDateString('en-US') + " " + startDateAux.getHours().toString() + ":" + startDateAux.getMinutes().toString();
      var fechFinal = this.endDate.toLocaleDateString('en-US') + " " + endDateAux.getHours().toString() + ":" + endDateAux.getMinutes().toString();

      this.requestSearch.FechaInicio = formatDate(fechInicio, 'dd/MM/yyyy HH:mm', 'en-US');
      this.requestSearch.FechaFin = formatDate(fechFinal, 'dd/MM/yyyy HH:mm', 'en-US');
    }

    this.service.getTransactionsPendingCapture(this.requestSearch)
      .subscribe(pnrs => {
        if (pnrs !== null) {
          pnrs.response.forEach(element => {
            const voidWP = new PnrsVoidWorldpay();
            voidWP.pnr = element.pnr;
            voidWP.fechaTransaccion = element.fechaTransaccion;
            voidWP.canal = element.canal;
            voidWP.moneda = element.moneda;
            voidWP.pais = element.pais;
            this.listPnrs.push(voidWP);
          });
          this.configureTable();
        } else {
          Swal.fire('La búsqueda no arrojo resultados', '', 'info');
        }
        this.spinnerService.hide();
      }, err => {
        this.spinnerService.hide();
      });
  }

  selectAll(indexPnrInicial: number): void {
    var indexPnrFinal = this.listPnrs.length;
    for (let i = indexPnrInicial - 1; i <= indexPnrFinal - 1; i++) {
      this.listPnrs[i].selected = this.selectedAll;
    }
  }

  changeDisableSelected(event: any) {
    this.blockAllFP = event.target.checked;
  }

  emitDisable(pnr: PnrsVoidWorldpay): boolean {
    return false;
  }

  checkIfAllSelected(): void {
    this.selectedAll = this.listPnrs.every(function (item: any): boolean {
      return item.selected === true;
    });

    this.blockAllFP = this.selectedAll;
  }

  checkIfaItemIsSelected(): void {
    for (const pnr of this.listPnrs) {
      if (pnr.selected) {
        this.selectedAItem = true;
        return;
      }
    }
    this.selectedAItem = false;
  }

  sendAuthPendingVoid(): void {
    let mensaje: string = "Fallido: ";
    this.spinnerService.show();
    this.requestVoid.request = this.getListPnrSelected();
    this.service.sendVoid(this.requestVoid).subscribe(response => {

      this.resultVoid = response;
      this.resultVoid.Failed.forEach(element => {
        let statusPnr: PnrStateful = new PnrStateful();
        statusPnr.canal = element.canal;
        statusPnr.moneda = element.moneda;
        statusPnr.pais = element.pais;
        statusPnr.pnr = element.pnr;
        statusPnr.status = mensaje.concat(element.error);
        this.listpnrEmitidos.push(statusPnr);
      });

      this.resultVoid.Success.forEach(element => {
        let statusPnr: PnrStateful = new PnrStateful();
        statusPnr.canal = element.canal;
        statusPnr.pais = element.pais;
        statusPnr.pnr = element.pnr;
        statusPnr.moneda = element.moneda;
        statusPnr.status = 'Cancelado';
        this.listpnrEmitidos.push(statusPnr);
      });

      this.configureTableResult();
      this.claerdp();
      this.spinnerService.hide();
    }, err => {
      console.log(err);
      this.spinnerService.hide();
      this.claerdp();
    });
  }

  configureTableResult(): void {
    this.mdbTablePaginationResult.setMaxVisibleItemsNumberTo(this.mdbTablePaginationResult.maxVisibleItems);
    this.tableE2.setDataSource(this.listpnrEmitidos);
    this.listpnrEmitidos = this.tableE2.getDataSource();
    this.previous = this.tableE2.getDataSource();

    this.mdbTablePaginationResult.calculateFirstItemIndex();
    this.mdbTablePaginationResult.calculateLastItemIndex();
    this.mdbTablePaginationResult.firstPage();
    this.cdRef.detectChanges();
  }

  getListPnrSelected(): PnrVoid[] {
    let listPnrSelected: PnrVoid[] = [];
    for (const item of this.listPnrs) {
      const pnrSelected = new PnrVoid();
      if (item.selected) {
        pnrSelected.moneda = item.moneda;
        pnrSelected.pais = item.pais;
        pnrSelected.canal = item.canal;
        pnrSelected.pnr = item.pnr;
        listPnrSelected.push(pnrSelected);
      }
    }
    return listPnrSelected;
  }

  checkIfCanNotEmit(): boolean {
    this.checkIfaItemIsSelected();

    if (this.selectedAItem) return false;
    else return true;
  }

  ngAfterViewInit() {
    this.configureTable();  
  }

  configureTable(): void {
    
    if(this.mdbTablePagination) {
      this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.mdbTablePaginationResult.maxVisibleItems);
      this.tableEl.setDataSource(this.listPnrs);
      this.listPnrs = this.tableEl.getDataSource();
      this.previous = this.tableEl.getDataSource();
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.mdbTablePagination.firstPage();
    }
    this.cdRef.detectChanges();
    
    
    
  }

}
