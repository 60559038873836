import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { RelanzamientosService } from '../../services/relanzamientos.service';
import { Relanzamiento, Response } from '../../shared/relanzamiento';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { RequestSearchService } from '../../shared/requestSearchService';
import { Router, ActivatedRoute } from '@angular/router';
import { RequestServiceEmision, Pnr } from '../../shared/requestserviceemision';
import { ResponseServiceEmision, Success, Failed, PnrStateful } from '../../shared/responseserviceemision';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatDate, UpperCasePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { stringify } from '@angular/compiler/src/util';


@Component({
  selector: 'app-relanzamientos',
  templateUrl: './relanzamientos.component.html',
  styleUrls: ['./relanzamientos.component.css']
})
export class RelanzamientosComponent implements OnInit, AfterViewInit {
  @ViewChild('mdbTablePagination', { static: true })  mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('mdbTablePaginationResult', { static: true })  mdbTablePaginationResult: MdbTablePaginationComponent;
  @ViewChild('tableEl', { static: true }) tableEl: MdbTableDirective;
  @ViewChild('tableE2', { static: true }) tableE2: MdbTableDirective;
  @Output() newItemEvent = new EventEmitter<RequestServiceEmision>();
  filterPnr: string;
  dateInitial: NgbDateStruct;
  dateFinal: NgbDateStruct;
  allItemsSelected: boolean;
  startDate: Date;
  endDate: Date;
  listpnrs: Relanzamiento[] = [];
  previous: any = [];
  selectedAll: any;
  selectedAllFP: any;
  selectedAItem: boolean;
  selectedAItemFP: boolean;
  requestService: RequestSearchService;
  requestServiceEmision: RequestServiceEmision;
  resultEmision: ResponseServiceEmision;
  listPnrSucces: Success;
  listPnrFallidos: Failed;
  listpnrEmitidos: PnrStateful[] = [];
  blockAllSelect: boolean;
  blockAllFP: boolean;
  public startAt = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDay(),0,0);
  public endAt = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDay(),23,59);
  public max = new Date();
  public min = new Date();
  fechaLocal: any;

  // tslint:disable-next-line: max-line-length
  constructor(private relanzamientosService: RelanzamientosService, private cdRef: ChangeDetectorRef, private router: Router, private activatedRoute: ActivatedRoute, private spinnerService: NgxSpinnerService) {
    this.selectedAItem = false;
    this.selectedAItemFP = false;
    this.requestService = new RequestSearchService();
    this.requestService.PNR = [];
    this.requestServiceEmision = new RequestServiceEmision();
    this.requestServiceEmision.request = [];

  }

  ngOnInit(): void {

  }

  getListPnrSelected(): Pnr[] {
    // tslint:disable-next-line: prefer-const
    let listPnrSelected: Pnr[] = [];

    for (const item of this.listpnrs) {
      const pnrSelected = new Pnr();
      console.log(item.pnr + ' ' + item.selected +' '+ item.agregarFP);
      if (item.selected === true || item.agregarFP === true) {
        pnrSelected.pnr = item.pnr;
        pnrSelected.lastName = item.lastName;
        if (item.agregarFP){
          pnrSelected.FP = 1;
        }else{
          pnrSelected.FP = 0;
        }
        pnrSelected.oid = item.oidEmision;
        pnrSelected.lang = item.lenguaje;
        pnrSelected.paymentMethod = item.formaDePago;
        if (item.esTimeToThink == "SI"){
          pnrSelected.ttt = 1;
        }
        else{
          pnrSelected.ttt = 0;
        }
        console.log(pnrSelected.pnr);
        listPnrSelected.push(pnrSelected);

      }
    }

    return listPnrSelected;
  }


  sendEmisiones(): void {
    let mensaje: string ="Fallido: ";
    this.spinnerService.show();
    this.requestServiceEmision.request = this.getListPnrSelected();
    this.relanzamientosService.sendEmisiones(this.requestServiceEmision).subscribe(response => {

      this.resultEmision = response;

      this.resultEmision.Failed.forEach(element => {
        // tslint:disable-next-line: prefer-const
        let statusPnr: PnrStateful = new PnrStateful();
        statusPnr.Pnr = element.pnr;
        statusPnr.lasName = element.lastName;
        if(this.resultEmision.validationError == true){
          statusPnr.status = mensaje.concat('Campo: ' + element.field + ', Valor: ' + element.value + ', Mensaje: ' + element.message);
        }
        else {
          statusPnr.status = mensaje.concat(element.error);
        }
        this.listpnrEmitidos.push(statusPnr);
      });
      this.resultEmision.Success.forEach(element => {
        // tslint:disable-next-line: prefer-const
        let statusPnr: PnrStateful = new PnrStateful();
        statusPnr.Pnr = element.pnr;
        statusPnr.lasName = element.lastName;
        statusPnr.status = 'Emitido';
        this.listpnrEmitidos.push(statusPnr);
      });
      console.log(this.listpnrEmitidos);
      this.configureTableResult();
      this.claerdp();
      this.spinnerService.hide();
    }, err => {
      console.log(err);
      this.spinnerService.hide();
      this.claerdp();
    });

  }
  selectAll(indexPnrInicial:number): void {
    // tslint:disable-next-line: prefer-for-of
    debugger;
    var indexPnrFinal = this.listpnrs.length;
    for (let i = indexPnrInicial-1; i <= indexPnrFinal-1; i++) {
      this.listpnrs[i].selected = this.selectedAll;
      if(this.listpnrs[i].tipoFallo==1){
        this.listpnrs[i].blockFP = true;
      }
      this.listpnrs[i].agregarFP=false;
      this.listpnrs[i].blockSelected = false;
    }
  }

  selectAllFP(indexPnrInicial:number): void {
    // tslint:disable-next-line: prefer-for-of
    var indexPnrFinal = this.listpnrs.length;
    for (let i = indexPnrInicial-1; i <= indexPnrFinal-1; i++) {

      if(this.listpnrs[i].tipoFallo==1){
          this.listpnrs[i].agregarFP = this.selectedAllFP;
          this.listpnrs[i].blockFP = false;
      }
      this.listpnrs[i].selected=false;
      this.listpnrs[i].blockSelected = true;
    }
  }

  changeDisableFP(event:any):void{
    if(!event.target.checked)
    {
      for(let i=0; i < this.listpnrs.length; i++)
      {
        this.listpnrs[i].blockSelected = false;
      }
      this.blockAllSelect=false;
    }
    else
      this.blockAllSelect=true;
  }

  changeDisableSelected(event:any)
  {
    if(!event.target.checked)
    {
      for(let i=0; i<this.listpnrs.length; i++){
        if(this.listpnrs[i].tipoFallo==1)
          this.listpnrs[i].blockFP = false;
      }
      this.blockAllFP=false;
    }
    else
      this.blockAllFP=true;
  }

  checkIfAllSelected(): void {
    // tslint:disable-next-line: only-arrow-functions
    this.selectedAll = this.listpnrs.every(function(item: any): boolean {
      return item.selected === true;
    });
    if(this.selectedAll)
        this.blockAllFP=true;
    else
        this.blockAllFP=false;
  }

  checkIfAllSelectedFP(): void {
    // tslint:disable-next-line: only-arrow-functions
    this.selectedAllFP = this.listpnrs.every(function(item: any): boolean {
      return (item.agregarFP === true || item.tipoFallo==0)
    });

    if(this.selectedAllFP==true)
      this.blockAllSelect=true;
    else
      this.blockAllSelect=false;

  }

  checkIfaItemIsSelected(): void {

    for (const pnr of this.listpnrs) {
      if (pnr.selected) {
        this.selectedAItem = true;
        return;
      }
    }
    this.selectedAItem = false;
  }
  checkIfaItemIsSelectedFP(): void {

    for (const pnr of this.listpnrs) {
      if (pnr.agregarFP) {
        this.selectedAItemFP = true;
        return;
      }
    }
    this.selectedAItemFP = false;
  }

  checkIfCanNotEmit():boolean
   {
     if(this.selectedAItem == true || this.selectedAItemFP==true)
       return false;
     else
       return true;
   }

   disableSelected(event:any, pnr:Relanzamiento): void{
    var elementTar=event.target;

     if(elementTar.checked)
     {
       pnr.blockSelected = true;

     }
     else if(!elementTar.checked)
     {
       pnr.blockSelected = false;
     }
   }

   disableFPCheck(event:any, pnr:Relanzamiento){
     var elementTar=event.target;
     if(elementTar.checked)
     {
       if(pnr.tipoFallo==1)
       {
         pnr.blockFP=true;
       }
     }
     else if(!elementTar.checked)
     {
       if(pnr.tipoFallo==1)
       {
         pnr.blockFP = false;
       }
     }
   }

   emitDisable(pnr:Relanzamiento):boolean{
     if(pnr.blockSelected==true || pnr.estado.toLowerCase()=="emitido manual")
          return true;
     else
          return false;
   }

   disableAllFP(){
     if(this.listpnrs.length==1)
     {
       if(this.listpnrs[0].estado.toLocaleLowerCase()=="emitido manual")
          this.blockAllFP=true;
     }

     return this.blockAllFP;

   }

   disableAllSelect(){
     if(this.listpnrs.length==1)
     {
       if(this.listpnrs[0].estado.toLocaleLowerCase()=="emitido manual")
            this.blockAllSelect=true;
     }

     return this.blockAllSelect;
   }

  claerdp(): void {

    this.dateInitial = null;
    this.dateFinal = null;
    this.requestService.FechaInicio = null;
    this.requestService.FechaFin = null;
    this.startDate = null;
    this.endDate = null;
  }

  clearPnr(): void {
    this.filterPnr = null;
    this.requestService.PNR = [];
  }

  findPnrs(): any {
    this.selectedAll = false;
    this.spinnerService.show();
    this.listpnrEmitidos = [];
    this.listpnrs = [];
    this.selectedAllFP=false;
    this.selectedAll=false;
    this.blockAllFP = false;
    this.blockAllSelect = false;
    if (this.filterPnr != null) {
      this.requestService.PNR[0] = this.filterPnr;
      this.requestService.FechaInicio = '';
      this.requestService.FechaFin = '';
    } else {
      //Se agregan 5 horas a las fechas de inicio y fin para transformar las fechas en hora zulu
      this.startDate.setHours(this.startDate.getHours()+5);
      this.endDate.setHours(this.endDate.getHours()+5);
      var fechInicio = this.startDate.toLocaleDateString('en-US') + " " + this.startDate.getHours().toString() + ":" + this.startDate.getMinutes().toString();
      var fechFinal = this.endDate.toLocaleDateString('en-US') + " " + this.endDate.getHours().toString() + ":" + this.endDate.getMinutes().toString();
      this.requestService.FechaInicio = formatDate(fechInicio, 'dd/MM/yyyy HH:mm', 'en-US');
      this.requestService.FechaFin = formatDate(fechFinal, 'dd/MM/yyyy HH:mm', 'en-US');
      console.log(this.requestService.FechaFin);
    }

    this.relanzamientosService.getRelanzamientos(this.requestService)
      .subscribe(relanzamientos => {
        if (relanzamientos != null) {
          relanzamientos.response.forEach(element => {
            const relanzamiento = new Relanzamiento();
            relanzamiento.categoriaAmadeus = element.categoriaAmadeus;
            relanzamiento.colaAmadeus = element.colaAmadeus;
            relanzamiento.direccion = element.direccion;
            relanzamiento.estado = element.estado;
            if(relanzamiento.estado != "Emitido Manual"){
              if(element.tipoFallo == 1){
                relanzamiento.estado='Pendiente FP';
              }
            }
            if(element.esTimeToThink == 1){
              relanzamiento.esTimeToThink = 'SI';
            }
            else if(element.esTimeToThink == 0){
              relanzamiento.esTimeToThink = 'NO';
            }
            else{
              relanzamiento.esTimeToThink = "No Identificado";
            }
            if(element.lenguaje != null){
              relanzamiento.lenguaje = element.lenguaje.toLocaleUpperCase();
            }
            else{
              relanzamiento.lenguaje = "No Identificado";
            }
            //Se transforma la fecha restandole 5 horas por tener hora zulu
            this.formateaHoraZulu(element.fechaTransaccion);
            relanzamiento.fechaTransaccion = this.fechaLocal.toLocaleString();
            relanzamiento.formaDePago = element.formaDePago;
            relanzamiento.lastName = element.lastName;
            relanzamiento.monto = element.monto;
            relanzamiento.nombres = element.nombres;
            relanzamiento.pnr = element.pnr;
            relanzamiento.telefono = element.telefono;
            relanzamiento.tipoFallo = element.tipoFallo;
            relanzamiento.oidEmision = element.oidEmision;
            this.listpnrs.push(relanzamiento);

          });
          console.log(this.listpnrs);
          this.configureTable();

        } else {

          Swal.fire('La búsqueda no arrojo resultados', '', 'info');
        }
        this.spinnerService.hide();
      }
        , err => {
          console.log(err);
          this.spinnerService.hide();
        }
      );


  }

  configureTable(): void {
    debugger;
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.mdbTablePaginationResult.maxVisibleItems);
    this.tableEl.setDataSource(this.listpnrs);
    this.listpnrs = this.tableEl.getDataSource();
    this.previous = this.tableEl.getDataSource();


    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.mdbTablePagination.firstPage();
    this.cdRef.detectChanges();
  }

  configureTableResult(): void {
    debugger;
    this.mdbTablePaginationResult.setMaxVisibleItemsNumberTo(this.mdbTablePaginationResult.maxVisibleItems);
    this.tableE2.setDataSource(this.listpnrEmitidos);
    this.listpnrEmitidos = this.tableE2.getDataSource();
    this.previous = this.tableE2.getDataSource();

    this.mdbTablePaginationResult.calculateFirstItemIndex();
    this.mdbTablePaginationResult.calculateLastItemIndex();
    this.mdbTablePaginationResult.firstPage();
    this.cdRef.detectChanges();
  }

  formateaHoraZulu(fechaZulu:string): void {
    var formatfecha = fechaZulu.replace('.','m');
    var fecha = formatfecha.split(' ',3);
    var fechaT = fecha[0].split('/',3);
    this.fechaLocal = new Date(formatDate(fechaT[1] + '/' + fechaT[0] + '/' + fechaT[2] + ' ' + fecha[1] + ' ' + fecha[2] , 'MM/dd/yyyy hh:mm:ss a', 'en-US'));
    this.fechaLocal.setHours(this.fechaLocal.getHours()-5);
  }

  cambiafecha():void{
    this.endAt=new Date(this.startDate);
    //this.endAt.setHours(23,59);
    this.min=this.endAt;
  }

  ngAfterViewInit(): void {

  }



}

