
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CancelacionesService } from '../../services/cancelaciones.service';
import { PnrsCancelarUatp, Response } from '../../shared/PnrsCancelarUatp';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { RequestSearchService } from '../../shared/requestSearchService';
import { Router, ActivatedRoute } from '@angular/router';
import { Requestservicevoid, PnrVoid } from '../../shared/requestservicevoid';
import { ResponseServiceVoid, Success, Failed, PnrStateful } from '../../shared/responseservicevoid';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatDate, UpperCasePipe } from '@angular/common';
import Swal from 'sweetalert2';

import { requestSearchServiceUatp } from 'src/app/shared/requestSearchServiceUatp';

import { request } from 'https';
@Component({
  selector: 'app-cancelaciones-uatp',
  templateUrl: './cancelaciones-uatp.component.html',
  styleUrls: ['./cancelaciones-uatp.component.css']
})
export class CancelacionesUatpComponent implements OnInit, AfterViewInit {
  @ViewChild('mdbTablePagination', { static: true })  mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('mdbTablePaginationResult', { static: true })  mdbTablePaginationResult: MdbTablePaginationComponent;
  @ViewChild('tableEl', { static: true }) tableEl: MdbTableDirective;
  @ViewChild('tableE2', { static: true }) tableE2: MdbTableDirective;
  @Output() newItemEvent = new EventEmitter<Requestservicevoid>();
  filterPnr: string;
  dateInitial: NgbDateStruct;
  dateFinal: NgbDateStruct;
  allItemsSelected: boolean;
  startDate: Date;
  endDate: Date;
  listpnrs: PnrsCancelarUatp[] = [];
  previous: any = [];
  selectedAll: any;
  selectedAllFP: any;
  selectedAItem: boolean;
  selectedAItemFP: boolean;
  requestService: requestSearchServiceUatp;
  requestServiceVoid: Requestservicevoid;
  resultVoid: ResponseServiceVoid;
  listPnrSucces: Success;
  listPnrFallidos: Failed;
  listpnrEmitidos: PnrStateful[] = [];
  blockAllSelect: boolean;
  blockAllFP: boolean;
  public startAt = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDay(),0,0);
  public endAt = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDay(),23,59);
  public max = new Date();
  public min = new Date();
  fechaLocal: any;

  // tslint:disable-next-line: max-line-length
  constructor(private cancelacionesService: CancelacionesService, private cdRef: ChangeDetectorRef, private router: Router, private activatedRoute: ActivatedRoute, private spinnerService: NgxSpinnerService) {
    this.selectedAItem = false;
    this.selectedAItemFP = false;
    this.requestService = new RequestSearchService();
   
    this.requestServiceVoid = new Requestservicevoid();
    this.requestServiceVoid.request = [];

  }

  ngOnInit(): void {

  }

  getListPnrSelected(): PnrVoid[] {
    // tslint:disable-next-line: prefer-const
    let listPnrSelected: PnrVoid[] = [];

    for (const item of this.listpnrs) {
      const pnrSelected = new PnrVoid();
      console.log(item.pnr + ' ' + item.selected);
      if (item.selected === true ) {
        pnrSelected.pnr = item.pnr;
        pnrSelected.transactionId = item.numAutorization;
        
        console.log(pnrSelected.pnr);
        listPnrSelected.push(pnrSelected);

      }
    }

    return listPnrSelected;
  }

  
  sendCancelaciones(): void {
    debugger;
    let mensaje: string ="Fallido: ";
    this.spinnerService.show();
    this.requestServiceVoid.request = this.getListPnrSelected();
    this.cancelacionesService.sendVoid(this.requestServiceVoid).subscribe(response => {
      
      this.resultVoid = response;

      this.resultVoid.failed.forEach(element => {
        // tslint:disable-next-line: prefer-const
        let statusPnr: PnrStateful = new PnrStateful();
        statusPnr.Pnr = element.pnr;
        statusPnr.transactionId = element.transactionId;
        if(this.resultVoid.validationError == true){
          statusPnr.status = mensaje.concat('Campo: ' + element.field + ', Valor: ' + element.value + ', Mensaje: ' + element.message);
        }
        else {
          statusPnr.status = mensaje.concat(element.error);
        }
        this.listpnrEmitidos.push(statusPnr);
      });
      this.resultVoid.success.forEach(element => {
       
        let statusPnr: PnrStateful = new PnrStateful();
        statusPnr.Pnr = element.pnr;
        statusPnr.transactionId = element.transactionId;
        statusPnr.status = 'Cancelado';
        this.listpnrEmitidos.push(statusPnr);
      });
      console.log(this.listpnrEmitidos);
      this.configureTableResult();
      this.claerdp();
      this.spinnerService.hide();
    }, err => {
      console.log(err);
      this.spinnerService.hide();
      this.claerdp();
    });
  }

  selectAll(indexPnrInicial:number): void {
 
    var indexPnrFinal = this.listpnrs.length;
    for (let i = indexPnrInicial-1; i <= indexPnrFinal-1; i++) {
      this.listpnrs[i].selected = this.selectedAll;
   
    }
  }

  selectAllFP(indexPnrInicial:number): void {
 
    var indexPnrFinal = this.listpnrs.length;
    for (let i = indexPnrInicial-1; i <= indexPnrFinal-1; i++) {

     
      this.listpnrs[i].selected=false;
     
    }
  }

  changeDisableFP(event:any):void{
    if(!event.target.checked)
    {
      for(let i=0; i < this.listpnrs.length; i++)
      {
      //  this.listpnrs[i].blockSelected = false;
      }
      this.blockAllSelect=false;
    }
    else
      this.blockAllSelect=true;
  }

  changeDisableSelected(event:any)
  {
    if(!event.target.checked)
    {
      for(let i=0; i<this.listpnrs.length; i++){
       // if(this.listpnrs[i].tipoFallo==1)
       //   this.listpnrs[i].blockFP = false;
      }
      this.blockAllFP=false;
    }
    else
      this.blockAllFP=true;
  }

  checkIfAllSelected(): void {
    // tslint:disable-next-line: only-arrow-functions
    this.selectedAll = this.listpnrs.every(function(item: any): boolean {
      return item.selected === true;
    });
    if(this.selectedAll)
        this.blockAllFP=true;
    else
        this.blockAllFP=false;
  }

  checkIfAllSelectedFP(): void {
    // tslint:disable-next-line: only-arrow-functions
    this.selectedAllFP = this.listpnrs.every(function(item: any): boolean {
      return (item.agregarFP === true || item.tipoFallo==0)
    });

    if(this.selectedAllFP==true)
      this.blockAllSelect=true;
    else
      this.blockAllSelect=false;

  }

  checkIfaItemIsSelected(): void {

    for (const pnr of this.listpnrs) {
      if (pnr.selected) {
        this.selectedAItem = true;
        return;
      }
    }
    this.selectedAItem = false;
  }
  

 checkIfCanNotEmit():boolean
   {
      if(this.selectedAItem == true || this.selectedAItemFP==true)
       return false;
     else
       return true;
   };


   emitDisable(pnr:PnrsCancelarUatp):boolean{
    
          return false;
   }

  claerdp(): void {

    this.dateInitial = null;
    this.dateFinal = null;
    this.requestService.FechaInicio = null;
    this.requestService.FechaFin = null;
    this.startDate = null;
    this.endDate = null;
  }

  clearPnr(): void {
    this.filterPnr = null;
    this.requestService.PNR = [];
  }

  findPnrs(): any {
    this.selectedAll = false;
    this.spinnerService.show();
    this.listpnrEmitidos = [];
    this.listpnrs = [];
    this.selectedAllFP=false;
    this.selectedAll=false;
    this.blockAllFP = false;
    this.blockAllSelect = false;
    if (this.filterPnr != null) {
      this.requestService.PNR[0] = this.filterPnr;
      this.requestService.FechaInicio = '';
      this.requestService.FechaFin = '';
    } else {
      //Se agregan 5 horas a las fechas de inicio y fin para transformar las fechas en hora zulu
      this.startDate.setHours(this.startDate.getHours()+5);
      this.endDate.setHours(this.endDate.getHours()+5);
      var fechInicio = this.startDate.toLocaleDateString('en-US') + " " + this.startDate.getHours().toString() + ":" + this.startDate.getMinutes().toString();
      var fechFinal = this.endDate.toLocaleDateString('en-US') + " " + this.endDate.getHours().toString() + ":" + this.endDate.getMinutes().toString();
      this.requestService.FechaInicio = formatDate(fechInicio, 'dd/MM/yyyy HH:mm', 'en-US');
      this.requestService.FechaFin = formatDate(fechFinal, 'dd/MM/yyyy HH:mm', 'en-US');
      console.log(this.requestService.FechaFin);
    }

    this.cancelacionesService.getPnrUatp(this.requestService)
      .subscribe(pnrs => {
      
        if (pnrs != null) {
          pnrs.response.forEach(element => {
            const cancelaciones = new PnrsCancelarUatp();
            cancelaciones.pnr = element.pnr;
            //this.formateaHoraZulu(element.transactionDate);
            cancelaciones.fechaTransaccion =  element.fechaTransaccion;//this.fechaLocal.toLocaleString();;
            cancelaciones.numAutorization = element.transactionId;
           
            //Se transforma la fecha restandole 5 horas por tener hora zulu
          
          
            this.listpnrs.push(cancelaciones);

          });
          console.log(this.listpnrs);
          this.configureTable();

        } else {

          Swal.fire('La búsqueda no arrojo resultados', '', 'info');
        }
        this.spinnerService.hide();
      }
        , err => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
  }

  configureTable(): void {
    debugger;
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.mdbTablePaginationResult.maxVisibleItems);
    this.tableEl.setDataSource(this.listpnrs);
    this.listpnrs = this.tableEl.getDataSource();
    this.previous = this.tableEl.getDataSource();


    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.mdbTablePagination.firstPage();
    this.cdRef.detectChanges();
  }

  configureTableResult(): void {
    debugger;
    this.mdbTablePaginationResult.setMaxVisibleItemsNumberTo(this.mdbTablePaginationResult.maxVisibleItems);
    this.tableE2.setDataSource(this.listpnrEmitidos);
    this.listpnrEmitidos = this.tableE2.getDataSource();
    this.previous = this.tableE2.getDataSource();

    this.mdbTablePaginationResult.calculateFirstItemIndex();
    this.mdbTablePaginationResult.calculateLastItemIndex();
    this.mdbTablePaginationResult.firstPage();
    this.cdRef.detectChanges();
  }

  formateaHoraZulu(fechaZulu:string): void {
    var formatfecha = fechaZulu.replace('.','m');
    var fecha = formatfecha.split(' ',3);
    var fechaT = fecha[0].split('/',3);
    this.fechaLocal = new Date(formatDate(fechaT[1] + '/' + fechaT[0] + '/' + fechaT[2] + ' ' + fecha[1] + ' ' + fecha[2] , 'MM/dd/yyyy hh:mm:ss a', 'en-US'));
    this.fechaLocal.setHours(this.fechaLocal.getHours()-5);
  }

  cambiafecha():void{
    this.endAt=new Date(this.startDate);
    //this.endAt.setHours(23,59);
    this.min=this.endAt;
  }

  ngAfterViewInit(): void {

  }
}

