<app-navbar></app-navbar>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="container">
    <form class="form-inline my-5" #form>
        <div class="form-group">
            <div class="container">
                <div class="input-group text-app-color">
                    <label class="col-form-label" for="pnr">Seleccione un archivo:</label>
                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                        <input type="file" class="col-form-label col-xs-auto col-sm-auto col-md-auto" (change)="onChange(uploads.files)" name="files" #uploads/>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <hr/>
        <div class="card border-stylish-color-dark my-5" [hidden]="listTGenericas?.length==0 || listpnrEmitidos?.length>0">
            <div class="card-header text-light">
                <div class="card-body text-app-color">
                    <h5 class="card-title">Carga de información</h5>
                    <hr/>
                    <div class="col-auto">
                        <table mdbTable #tableEl="mdbTable" class="table table-bordered table-striped table-hover table-sm table-responsive-lg">
                            <thead>
                                <tr>
                                    <th class="text-center">PNR</th>
                                    <th class="text-center">Apellidos</th>
                                    <th class="text-center">Franquicia</th>
                                    <th class="text-center">OfficeID</th>
                                    <th class="text-center">MedioPago</th>
                                    <th class="text-center">Cus</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let record of listTGenericas; let i = index">
                                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center"> {{record.pnr}} </td>
                                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center"> {{record.apellidos}} </td>
                                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center"> {{record.franquicia}} </td>
                                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center"> {{record.officeID}} </td>
                                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center"> {{record.mediopago}} </td>
                                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center"> {{record.cus}} </td>
                                </tr>
                            </tbody>
                            <tfoot class="grey lighten-5 w-100">
                                <tr>
                                    <td colspan="9">
                                        <mdb-table-pagination #mdbTablePagination [tableEl]="tableEl" [searchDataSource]="listTGenericas"></mdb-table-pagination>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                <hr/>
                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                        <button class="btn btn-danger" role="button" (click)='sendEmisiones()'>Emitir</button>
                    </div>
                </div>
            </div>
        </div>

        <div class=" card border-stylish-color-dark  my-5 " [hidden]="listpnrEmitidos?.length==0">
            <div class="card-header text-light  "></div>
            <div class="card-body text-app-color ">
                <h5 class="card-title">Resultado de la Emisión</h5>
                <div class="alert alert-info" *ngIf="listpnrEmitidos?.length==0">
                    Sin resultados de emisión!
                </div>
                <div class="col-auto">
    
                    <table mdbTable #tableE2="mdbTable" class="table table-bordered table-striped table-hover table-sm table-responsive-lg ">
                        <thead>
                            <tr>
                                <th class="text-center">PNR</th>
                                <th class="text-center">Apellido</th>
                                <th class="text-center">Franquicia</th>
                                <th class="text-center">OfficeID</th>
                                <th class="text-center">MedioPago</th>
                                <th class="text-center">Cus</th>
                                <th class="text-center">Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pnr of listpnrEmitidos; let i = index">
                                <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex " scope="row" class="text-center">{{pnr.pnr}}</td>
                                <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex" scope="row" class="text-center">{{pnr.lastName}}</td>
                                <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex" scope="row" class="text-center">{{pnr.franquicia}}</td>
                                <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex" scope="row" class="text-center">{{pnr.oid}}</td>
                                <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex" scope="row" class="text-center"> {{pnr.paymentMethod}} </td>
                                <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex" scope="row" class="text-center"> {{pnr.cus}} </td>
                                <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex" scope="row" class="text-center">{{pnr.status}}</td>
                            </tr>
                        </tbody>
                        <tfoot class="grey lighten-5 w-100">
                            <tr>
                                <td colspan="7">
                                    <mdb-table-pagination #mdbTablePaginationResult [tableEl]="tableE2" [searchDataSource]="listpnrEmitidos"></mdb-table-pagination>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
    
                </div>
            </div>
        </div>
</div>