import { PnrVoid } from './requestservicevoid';
export class ResponseServiceVoid {
    success: Success[];
    failed: Failed[];
    validationError : boolean;
}

export class Success {
    pnr: string;
    transactionId: string;
    
}

export class Failed {
    pnr: string;
    transactionId: string;
    error: string;
    field: string;
    value: string;
    message: string;
}

export class PnrStateful {
    Pnr: string;
    transactionId: string;
    status: string;
}
